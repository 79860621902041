import React from "react";
import { useQuery } from "react-query";
import { useLocation } from "react-router-dom";
import ErrorMessage from "../components/ErrorMessage";
import VideoCard from "../components/VideoCard";
import HomeSkeleton from "../skeletons/HomeSkeleton";
import Wrapper from "../styles/Home";
import VideoGrid from "../styles/VideoGrid";
import { client } from "../utils/api-client";

function Home() {
  const location = useLocation();

  const searchParams = new URLSearchParams(location.search);
  const category = searchParams.get("category") || "All";
  const {
    data: videos,
    isSuccess,
    isLoading,
    isError,
    error,
  } = useQuery(
    ["Home", category],
    () => {
      const categoryQuery = category !== "All" ? `?category=${category}` : "";
      return client
        .get(`/videos${categoryQuery}`)
        .then((res) => res.data.videos);
    },
    { refetchOnWindowFocus: true }
  );

  if (isLoading) return <HomeSkeleton />;
  if (isError) return <ErrorMessage error={error} />;

  return (
    <Wrapper>
      <VideoGrid>
        {isSuccess
          ? videos.map((video) => <VideoCard key={video.id} video={video} />)
          : null}
      </VideoGrid>
    </Wrapper>
  );
}

export default Home;
