import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import AppProviders from "./components/AppProviders";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
// const stripePromise = loadStripe(
//   "pk_test_51QfMtZBSjDQfgPeaTG7XpCigJuUKchMquLs7ZNq0WMGBbYOIQeNvUfzK22Zfrxqygo5nWgvb1Zt2SsoP3PEpxUsJ00eC5Lswx6"
// );
const stripePromise = loadStripe(
  "pk_live_51QfMtZBSjDQfgPeaHsy5XqaHRH6hQfmK9eLJNxw31fkOr8V3Y0tvan03Qg8cocWi2DcjMSQ2Di9UmrCUlEdpmomI00eA2l1Hrx"
);

ReactDOM.render(
  <AppProviders>
    <Elements stripe={stripePromise}>
      <App />
    </Elements>
  </AppProviders>,
  document.getElementById("root")
);
