import React, { useEffect } from "react";
import { RxCross1 } from "react-icons/rx";
import { queryCache, useQuery } from "react-query";
import { client } from "utils/api-client";
import CommentList from "./CommentList";
import { useAuth } from "context/auth-context";
import defaultAvatar from "../assets/default-avatar.png";
import { BsFillSendFill } from "react-icons/bs";
import { useSnackbar } from "react-simple-snackbar";

const ShortsCommentBox = ({ id, onHide, updateCommentsCount }) => {
  const [openSnackbar] = useSnackbar();
  const [comment, setComment] = React.useState("");
  const [comments, setComments] = React.useState([]);
  const user = useAuth();
  const {
    data,
    isLoading: isLoadingVideo,
    refetch,
  } = useQuery(["getComments"], () =>
    client.get(`/videos/${id}/comments`).then((res) => res.data)
  );

  useEffect(() => {
    if (data) setComments(data);
  }, [data]);

  const handleAddComment = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      addComment();
    }
  };

  const addComment = () => {
    if (!comment.trim()) return openSnackbar("Please write a comment");

    const commentObj = {
      createdAt: new Date(),
      text: comment,
      userId: user.id,
      videoId: id,
      user,
    };
    const newComments = [commentObj, ...comments];
    setComments(newComments);
    updateCommentsCount("add");
    setComment("");
    client.post(`/videos/${id}/comments`, { text: comment }).then(async () => {
      refetch();
      //   await queryCache.invalidateQueries("getComments");
    });
  };

  const removeComment = (id) => {
    const updateComments = comments.filter((comment) => comment.id !== id);
    setComments(updateComments);
    updateCommentsCount("remove");
  };

  return (
    <div className="comment-box active">
      <div className="inner-comment-box">
        <div className="comments-header d-flex justify-content-between align-items-center">
          <div className="comments-title d-flex align-items-center">
            <h3>Comments</h3>
            <span>{data?.length || 0}</span>
          </div>
          <div className="control-icon comment-close" onClick={onHide}>
            <RxCross1 size={23} />
          </div>
        </div>
        {comments && (
          <div className="comments-list px-3">
            <CommentList
              updateCommentsCount={removeComment}
              comments={comments}
            />
          </div>
        )}

        <div className="add-comment">
          {user ? (
            <img src={user.avatar} alt={user.username} />
          ) : (
            <img src={defaultAvatar} alt="default user" />
          )}
          <textarea
            placeholder="Add a public comment..."
            value={comment}
            onKeyDown={handleAddComment}
            onChange={(event) => setComment(event.target.value)}
            rows={1}
          />
          <div className="control-icon comment-close" onClick={addComment}>
            <BsFillSendFill size={18} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShortsCommentBox;
