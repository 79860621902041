import React, { useEffect, useRef, useState } from "react";
import Wrapper from "styles/EditProfileModal";
import { CloseIcon } from "./Icons";
import Button from "styles/Button";
import UploadVideoModal from "./UploadVideoModal";
import path from "path";
import { client } from "utils/api-client";
import LoadingAnimation from "./VideoLoadingAnimation";
import { uploadMedia } from "utils/upload-media";
import { useAuth } from "context/auth-context";

const GenerateVideoModal = ({ closeModal }) => {
  const user = useAuth();
  const video_uuid = localStorage.getItem("video_uuid");
  const [prompt, setPrompt] = useState("");
  const [loading, setLoading] = useState(false);
  const [showVideo, setShowVideo] = useState(false);
  const [status, setStatus] = useState(null);
  const [error, setError] = useState(null);
  const [videoUrl, setVideoUrl] = useState("");
  const [url, setUrl] = useState("");
  const [generationProgress, setGenerationProgress] = useState(0);
  const [thumbnail, setThumbnail] = useState("");
  const [quality, setQuality] = useState("720p");
  const mountedRef = useRef(true); // Ref to track mounted state
  const timeoutRef = useRef(null); // Ref to store the timeout ID

  useEffect(() => {
    if (video_uuid) {
      setLoading(true);
      checkVideoStatus(video_uuid);
    }
  }, [video_uuid]);

  useEffect(() => {
    // Set mountedRef to true when the component mounts
    mountedRef.current = true;

    // Cleanup function to run when the component unmounts
    return () => {
      mountedRef.current = false;
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current); // Clear any active timeouts
      }
    };
  }, []);

  async function urlToFile(
    url,
    filename = "AI Generated Video",
    mimeType = "video/mp4"
  ) {
    // Fetch the video from the URL
    const response = await fetch(url);

    // Convert the response into a Blob
    const blob = await response.blob();

    // Create a File from the Blob
    return new File([blob], filename, { type: mimeType });
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    client
      .post("/video_generation/generate", { prompt, quality })
      .then((res) => {
        localStorage.setItem("video_uuid", res.data.uuid);
        checkVideoStatus(res.data.uuid);
      })
      .catch((err) => {
        setError(err.response?.data?.message || "An error occurred");
        setLoading(false);
      });
  };

  const checkVideoStatus = async (uuid) => {
    try {
      const response = await client.get(`/video_generation/generated/${uuid}`);
      const { status, url, progress } = response.data;
      if (!mountedRef.current) return; // Exit if the component is unmounted

      setStatus(status);

      if (status === "success") {
        setVideoUrl(url);
        const fileFromUrl = await urlToFile(url);
        if (!mountedRef.current) return; // Exit if the component is unmounted
        setLoading(false);
        setShowVideo(true);
        const url_obj = await uploadMedia({
          type: "video",
          file: fileFromUrl,
          preset: "ai-tube",
        });

        const extension = path.extname(url_obj);
        setThumbnail(url_obj.replace(extension, ".jpg"));
        setUrl(url_obj);
      } else if (status === "failed") {
        setError("Video generation failed");
        setLoading(false);
        localStorage.removeItem("video_uuid");
      } else {
        // Continue polling every 5 seconds
        setGenerationProgress(progress);
        timeoutRef.current = setTimeout(
          () => {
            if (mountedRef.current) {
              checkVideoStatus(uuid);
            }
          },
          progress ? 3000 : 10000
        );
      }
    } catch (err) {
      if (mountedRef.current) {
        setError("Failed to check video status: " + err.message);
        setLoading(false);
      }
    }
  };

  return (
    <>
      {showVideo ? (
        <UploadVideoModal
          previewVideo={videoUrl}
          thumbnail={thumbnail}
          defaultTitle={"AI Generated Video"}
          url={url}
          closeModal={closeModal}
          modalTitle={"Video Generated"}
          type="generated"
        />
      ) : (
        <Wrapper>
          <div className="edit-profile">
            <div className="modal-header">
              <h3>
                <CloseIcon onClick={closeModal} />
                <span>
                  {loading
                    ? status === "submitted"
                      ? "Generating..."
                      : "In Queue"
                    : "Generate Video"}
                </span>
              </h3>
            </div>
            <div className="pb-1 mx-2">
              {loading ? (
                <div style={{ margin: "10px 0" }}>
                  <LoadingAnimation
                    progress={(generationProgress || 0) * 100}
                  />
                </div>
              ) : (
                <form onSubmit={handleSubmit}>
                  <textarea
                    value={prompt}
                    onChange={(e) => setPrompt(e.target.value)}
                    required
                    placeholder="Enter prompt to generate video"
                    style={{
                      width: "100%",
                      padding: "8px",
                      margin: "10px 0",
                      marginTop: "0px",
                      border: "1px solid #ccc",
                      borderRadius: "4px",
                      color: "white",
                    }}
                  ></textarea>
                  {user?.plan === "PRO" && (
                    <select
                      className="modal-select"
                      value={quality}
                      onChange={(event) => setQuality(event.target.value)}
                    >
                      <option value="720p">720p</option>
                      <option value="1080p">1080p</option>
                    </select>
                  )}
                  <Button
                    className="w-full"
                    style={{ marginTop: "10px" }}
                    type="submit"
                    //   disabled={!stripe || loading || (paymentDone && !error)}
                  >
                    Generate
                    {/* {loading
                ? "Loading..."
                : paymentDone && !error
                ? "Card Added"
                : "Add Card"} */}
                  </Button>
                  {/* {(error || cardError) && (
              <div className="card-error">{error || cardError}</div>
            )} */}
                </form>
              )}
            </div>
          </div>
        </Wrapper>
      )}
    </>
  );
};

export default GenerateVideoModal;
