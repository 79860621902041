import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";

const ProgressBarContainer = styled.div`
  width: 100%;
  height: 3px;
  background-color: ${(props) => props.theme.navyBlue};
  border-radius: 4px;
  overflow: hidden;
`;

const Progress = styled.div`
  width: ${(props) => props.value}%;
  height: 100%;
  background-color: ${(props) => props.theme.cyan};
  transition: width 0.3s ease-in-out;
`;

const ProgressBar = ({ player }) => {
  const progressRef = useRef(null);
  const [progress, setProgress] = useState(0);
  const [isDragging, setIsDragging] = useState(false);

  useEffect(() => {
    if (!player) return;

    // Update progress on timeupdate
    const handleTimeUpdate = () => {
      const duration = player.duration();
      const currentTime = player.currentTime();
      if (duration > 0) {
        const percentage = (currentTime / duration) * 100;
        setProgress(percentage);
      }
    };

    // Handle seeking when clicking on the progress bar
    const handleProgressBarClick = (e) => {
      const progressBar = progressRef.current.getBoundingClientRect();
      const clickPosition = (e.clientX - progressBar.left) / progressBar.width;
      const newTime = clickPosition * player.duration();
      player.currentTime(newTime);
    };

    // Add event listeners
    player.on("timeupdate", handleTimeUpdate);
    const progressBarElement = document.querySelector(
      ".progress-bar-container"
    );
    progressBarElement?.addEventListener("click", handleProgressBarClick);

    // Cleanup
    return () => {
      player.off("timeupdate", handleTimeUpdate);
      progressBarElement?.removeEventListener("click", handleProgressBarClick);
    };
  }, [player]);
  const calculateTimeFromPosition = (clientX) => {
    if (!progressRef.current) return 0;

    const progressBar = progressRef.current.getBoundingClientRect();
    const clickPosition = (clientX - progressBar.left) / progressBar.width;

    // Clamp the value between 0 and 1
    const clampedPosition = Math.min(Math.max(clickPosition, 0), 1);
    return clampedPosition * player.duration();
  };

  const handleMouseDown = (e) => {
    player.pause();
    setIsDragging(true);
    const newTime = calculateTimeFromPosition(e.clientX);
    player.currentTime(newTime);
  };

  const handleMouseMove = (e) => {
    if (!isDragging) return;

    const newTime = calculateTimeFromPosition(e.clientX);
    player.currentTime(newTime);
  };

  const handleMouseUp = () => {
    if (isDragging) {
      setIsDragging(false);
      player.play();
    }
  };

  // Add global mouseup listener to handle releasing outside the progress bar
  useEffect(() => {
    const handleGlobalMouseUp = () => setIsDragging(false);
    window.addEventListener("mouseup", handleGlobalMouseUp);

    return () => {
      window.removeEventListener("mouseup", handleGlobalMouseUp);
    };
  }, []);

  return (
    <div
      onMouseDown={handleMouseDown}
      onMouseMove={handleMouseMove}
      onMouseUp={handleMouseUp}
      className="py-2 cursor-pointer"
    >
      <ProgressBarContainer
        ref={progressRef}
        className="progress-bar-container"
      >
        <Progress value={progress} />
      </ProgressBarContainer>
    </div>
  );
};

export default ProgressBar;
