import styled from "styled-components";

const TextAvatar = styled.img`
  width: 36px;
  height: 36px;
  border-radius: 50%;
  display: grid;
  place-items: center;
  background: var(--gray-700);
  h2 {
    color: white;
    font-size: 1.5rem;
    font-weight: 500;
  }

  @media screen and (max-width: 500px) {
    width: 26px;
    height: 26px;
  }
`;

export default TextAvatar;
