import styled from "styled-components";

const Container = styled.div`
  width: 100%;
  position: relative;
`;

const Card = styled.div`
  background: ${(props) => props.gradient};
  border-radius: 16px;
  padding: 24px;
  color: white;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1);
  width: 100%;
  margin: 10px 0;
  &:hover {
    box-shadow: 0 20px 25px -5px rgb(0 0 0 / 0.1);
    transform: translateY(-2px);
  }
`;

const CardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 16px;
`;

const Label = styled.p`
  font-size: 0.875rem;
  opacity: 0.8;
  margin: 0;
  margin-bottom: 4px;
`;

const Value = styled.p`
  font-size: 1rem;
  font-weight: 500;
  margin: 0;
`;

const CardType = styled.div`
  font-weight: bold;
  text-transform: uppercase;
`;

const Dropdown = styled.div`
  position: absolute;
  top: calc(100% + 8px);
  max-height: 200px;
  left: 0;
  right: 0;
  background: white;
  border-radius: 12px;
  box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1);
  overflow: hidden;
  overflow-y: auto;
  z-index: 10;
  transform-origin: top;
  transform: ${(props) => (props?.isOpen ? "scaleY(1)" : "scaleY(0)")};
  opacity: ${(props) => (props?.isOpen ? "1" : "0")};
  transition: all 0.2s ease;

  // custom scrollbar
  &::-webkit-scrollbar {
    width: 8px;
    padding-right: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background: #c2c2c2; /* Color of the thumb */
    border-radius: 10px; /* Rounded corners for the thumb */
  }
`;

const DropdownCard = styled.div`
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  transition: background-color 0.2s ease;

  &:last-child {
    border-bottom: none;
  }

  &:hover {
    background-color: rgba(0, 0, 0, 0.05);
  }
`;

const CardInfo = styled.div`
  display: flex;
  flex-direction: column;
  cursor: pointer;
  width: 100%;
`;

const CardNumber = styled.span`
  font-weight: 500;
  color: #333;
`;

const ExpiryDate = styled.span`
  font-size: 0.875rem;
  color: #666;
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export {
  Container,
  Card,
  CardHeader,
  Label,
  Value,
  CardType,
  Dropdown,
  DropdownCard,
  CardInfo,
  CardNumber,
  ExpiryDate,
  IconWrapper,
};
