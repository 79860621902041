import React from "react";
import { addVideoView } from "utils/api-client";
import videojs from "video.js";
import "video.js/dist/video-js.css";

const ShortVideoPlayer = ({
  previewUrl,
  video,
  onPlayerReady,
  videoView = true,
  handleScrollDown,
}) => {
  const videoRef = React.useRef();

  const { id, url, thumbnail } = video;

  React.useEffect(() => {
    const vjsPlayer = videojs(videoRef.current, {
      controls: false, // Disable default controls
      fluid: true,
      playsinline: true,
      autoplay: true,
      muted: true,
    });
    onPlayerReady(vjsPlayer);

    vjsPlayer.poster(thumbnail);
    if (!previewUrl) {
      vjsPlayer.src({ type: "video/mp4", src: url });
    } else {
      vjsPlayer.src({ type: "video/mp4", src: previewUrl });
    }
    vjsPlayer.on("ended", () => {
      if (!videoView) return;
      addVideoView(id);
      handleScrollDown();
    });

    return () => {
      if (vjsPlayer) {
        vjsPlayer.dispose();
      }
    };
  }, [id, thumbnail, url, previewUrl]);

  return (
    <div data-vjs-player style={{ height: "100%", overflow: "hidden" }}>
      <video
        controls
        ref={videoRef}
        className="video-js vjs-big-play-centered"
        style={{ height: "100%", objectFit: "contain" }}
        preload="none"
      ></video>
    </div>
  );
};

export default ShortVideoPlayer;
