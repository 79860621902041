import React from "react";
import Wrapper from "../styles/PrivacyPolicy";

function PrivacyPolicy() {
  return (
    <Wrapper>
      <h1>Privacy Policy</h1>
      <p>Last updated: January 1, 2025</p>

      <p>
        Welcome to AITube! This Privacy Policy outlines how we collect, use, and
        protect your information while using our services. At AITube, your
        privacy is a priority. By accessing our Service, you agree to the
        practices described in this Privacy Policy.
      </p>

      <h1>Interpretation and Definitions</h1>

      <h2>Interpretation</h2>
      <p>
        Words with initial capital letters have defined meanings as detailed
        below. These definitions apply whether they appear in singular or
        plural.
      </p>

      <h2>Definitions</h2>
      <ul>
        <li>
          <strong>Account:</strong> A unique account created for you to access
          our Service or parts of it.
        </li>
        <li>
          <strong>Affiliate:</strong> Any entity under common control with, or
          controlled by, AITube.
        </li>
        <li>
          <strong>Application:</strong> The software program provided by AITube,
          downloadable on electronic devices.
        </li>
        <li>
          <strong>Company:</strong> refers to AITube, Inc, located in New York,
          United States.
        </li>
        <li>
          <strong>Device:</strong> Any device capable of accessing the Service
          (e.g., smartphone, computer, tablet).
        </li>
        <li>
          <strong>Personal Data:</strong> Any information relating to an
          identifiable individual.
        </li>
        <li>
          <strong>Service:</strong> Refers to AITube's Application and
          associated services.
        </li>
        <li>
          <strong>Usage Data:</strong> Data automatically collected during use
          of the Service.
        </li>
        <li>
          <strong>You:</strong> The user accessing or interacting with our
          Service.
        </li>
      </ul>

      <h1>Collection and Use of Your Data</h1>

      <h2>Types of Data Collected</h2>

      <h3>Personal Data</h3>
      <p>
        We may collect personally identifiable information, including but not
        limited to:
      </p>
      <ul>
        <li>Email address</li>
        <li>First and last name</li>
        <li>Usage Data</li>
      </ul>

      <h3>Usage Data</h3>
      <p>
        Usage Data is collected automatically and may include information such
        as your IP address, browser type, device information, and interaction
        with our Service. This helps us improve your experience.
      </p>

      <h3>Third-Party Social Media Services</h3>
      <p>
        If you register via third-party platforms like Google, Facebook, or
        Twitter, we may collect associated information to enhance your
        experience on AITube.
      </p>

      <h3>Information from Your Device</h3>
      <p>
        With your consent, we may access your device’s camera, photos, and media
        to provide core features of our Service. You can manage these
        permissions in your device settings.
      </p>

      <h2>How We Use Your Data</h2>
      <p>We use your data for purposes including:</p>
      <ul>
        <li>Providing and improving the Service</li>
        <li>Managing your account</li>
        <li>Communicating updates and promotions</li>
        <li>Analyzing usage trends to enhance functionality</li>
        <li>Ensuring compliance with legal and security standards</li>
      </ul>

      <h2>Data Sharing</h2>
      <p>Your information may be shared:</p>
      <ul>
        <li>With service providers for operational purposes</li>
        <li>During business transfers like mergers or acquisitions</li>
        <li>With affiliates and business partners</li>
        <li>With your consent for other purposes</li>
      </ul>

      <h2>Data Retention</h2>
      <p>
        We retain your data only for as long as necessary to fulfill its
        intended purpose or comply with legal requirements.
      </p>

      <h2>Data Security</h2>
      <p>
        While we implement best practices to secure your data, no method of
        electronic transmission or storage is 100% secure.
      </p>

      <h1>Children's Privacy</h1>
      <p>
        AITube is not intended for users under 13 years of age. We do not
        knowingly collect information from children. If you believe your child
        has provided personal data, contact us immediately.
      </p>

      <h1>External Links</h1>
      <p>
        Our Service may contain links to third-party websites. We are not
        responsible for their privacy practices or content.
      </p>

      <h1>Policy Updates</h1>
      <p>
        This Privacy Policy may be updated periodically. We will notify you of
        significant changes via email or through the Service.
      </p>

      <h1>Contact Us</h1>
      <p>
        For questions about this Privacy Policy, contact us at{" "}
        <a href="mailto:office@aivideostube.com">office@aivideostube.com</a>.
      </p>
    </Wrapper>
  );
}

export default PrivacyPolicy;
