import styled from "styled-components";

const Wrapper = styled.div`
  .social-icons {
    display: flex;
    gap: 15px;
    justify-content: center;
    align-items: center
  }}
`;

export default Wrapper;
