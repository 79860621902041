import React from "react";
import Wrapper from "styles/EditProfileModal";
import AddNewCard from "./AddNewCard";

const AddNewCardModal = ({ closeModal }) => {
  return (
    <Wrapper>
      <AddNewCard closeModal={closeModal} />
    </Wrapper>
  );
};

export default AddNewCardModal;
