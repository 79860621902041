import React, { useEffect, useState } from "react";
import Wrapper from "styles/EditProfileModal";
import { CloseIcon } from "./Icons";
import Button from "styles/Button";
import AddNewCard from "./AddNewCard";
import { useQuery } from "react-query";
import { FaCheck } from "react-icons/fa";
import { IoIosArrowDown } from "react-icons/io";
import { FaCreditCard } from "react-icons/fa6";
import { client } from "utils/api-client";
import {
  Container,
  Card,
  CardHeader,
  Label,
  Value,
  CardType,
  IconWrapper,
  Dropdown,
  DropdownCard,
  CardInfo,
  CardNumber,
  ExpiryDate,
} from "./CreditCardWrapper";
import { usePaymentMethod } from "hooks/use-payment-method";
import { MdDelete } from "react-icons/md";
import { useRemoveCard } from "hooks/use-remove-card";

const gradientMapping = {
  visa: "linear-gradient(135deg, #6366f1 0%, #4f46e5 100%)",
  mastercard: "linear-gradient(135deg, #3b82f6 0%, #1d4ed8 100%)",
  amex: "linear-gradient(135deg, #14b8a6 0%, #0d9488 100%)",
  default: "linear-gradient(135deg, #d1d5db 0%, #9ca3af 100%)", // Fallback for unknown types
};

const ViewCardsModal = ({ closeModal }) => {
  const { removeCard, loading: removeCardLoading } = useRemoveCard();
  const { setPaymentMethod, error, loading } = usePaymentMethod();
  const [addCard, setAddCard] = React.useState(false);
  const { data, isSuccess, isLoading, isError } = useQuery(
    ["Cards"],
    () => client.get("/payments/getCards").then((res) => res.data),
    { refetchOnWindowFocus: true }
  );

  const [isOpen, setIsOpen] = useState(false);
  const [selectedCard, setSelectedCard] = useState(null);
  const [defaultCard, setDefaultCard] = useState(null);
  const toggleDropdown = () => setIsOpen(!isOpen);

  const handleCardSelect = (card) => {
    setSelectedCard(card);
    setIsOpen(false);
  };

  useEffect(() => {
    if (data) {
      const defaultCard = data?.find((card) => card.isDefault);
      setDefaultCard(defaultCard || data[0]);
      setSelectedCard(defaultCard || data[0]);
    }
  }, [data]);

  const formatNum = (num) => {
    return num < 10 ? `0${num}` : num;
  };

  const handlePaymentMethod = async () => {
    const res = await setPaymentMethod(selectedCard.id);
    if (!error && !loading && res) {
      closeModal();
    }
  };

  const handleRemoveCard = async (paymentMethodId) => {
    await removeCard(paymentMethodId);
  };

  return (
    <Wrapper>
      {!addCard ? (
        <div className="edit-profile">
          <div className="modal-header">
            <h3>
              <CloseIcon onClick={closeModal} />
              <span>Cards</span>
            </h3>
            <Button
              disabled={loading || selectedCard?.isDefault || !selectedCard}
              onClick={handlePaymentMethod}
            >
              {loading ? "Updating..." : "Save"}
            </Button>
          </div>
          <div className="pb-1 mx-2">
            {isLoading ? (
              <p>Loading...</p>
            ) : isError ? (
              <p>Error fetching your cards</p>
            ) : !selectedCard ? (
              <p>No cards found</p>
            ) : (
              <Container>
                <Card
                  onClick={toggleDropdown}
                  gradient={
                    gradientMapping[selectedCard?.brand] ||
                    gradientMapping.default
                  }
                >
                  <CardHeader>
                    <div>
                      <Label>Card Number</Label>
                      <Value>{`•••• •••• •••• ${selectedCard?.last4}`}</Value>
                    </div>
                    <CardType>{selectedCard.brand}</CardType>
                  </CardHeader>
                  <div>
                    <Label>Card Holder</Label>
                    <Value>{selectedCard.name}</Value>
                  </div>
                  <CardHeader>
                    <div>
                      <Label>Expires</Label>
                      <Value>{`${formatNum(selectedCard.expMonth)}/${String(
                        selectedCard.expYear
                      ).slice(-2)}`}</Value>
                    </div>
                    <IconWrapper>
                      <IoIosArrowDown size={20} style={{ fill: "white" }} />
                    </IconWrapper>
                  </CardHeader>
                </Card>

                <Dropdown isOpen={isOpen}>
                  {data.map((card) => (
                    <DropdownCard key={card.id}>
                      <CardInfo onClick={() => handleCardSelect(card)}>
                        <CardNumber>{`•••• •••• •••• ${card?.last4}`}</CardNumber>
                        <ExpiryDate>
                          Expires{" "}
                          {`${formatNum(card.expMonth)}/${String(
                            card.expYear
                          ).slice(-2)}`}
                        </ExpiryDate>
                      </CardInfo>
                      <IconWrapper>
                        {card.id === selectedCard.id ? (
                          <FaCheck size={20} color="#4CAF50" />
                        ) : (
                          defaultCard?.id !== card.id && (
                            <MdDelete
                              onClick={() => handleRemoveCard(card.id)}
                              size={20}
                              color="#4CAF50"
                            />
                          )
                        )}
                        <FaCreditCard
                          onClick={() => handleCardSelect(card)}
                          size={20}
                        />
                      </IconWrapper>
                    </DropdownCard>
                  ))}
                </Dropdown>
              </Container>
            )}
            {error && (
              <p style={{ color: "red" }}>
                Error setting default card. Please try again later
              </p>
            )}
            <Button className="w-full" onClick={() => setAddCard(true)}>
              Add Card
            </Button>
          </div>
        </div>
      ) : (
        <AddNewCard closeModal={() => setAddCard(false)} />
      )}
    </Wrapper>
  );
};

export default ViewCardsModal;
