import { useState } from "react";
import { queryCache } from "react-query";
import { client } from "utils/api-client";
export const usePaymentMethod = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const setPaymentMethod = async (paymentMethodId) => {
    try {
      setLoading(true);
      setError(null);
      const res = await client.put("/payments/payment_method", {
        paymentMethodId,
      });
      await queryCache.invalidateQueries("Cards");
      return res;
    } catch (err) {
      setError(err.response?.data?.message || "An error occurred");
      console.error("Add Card error:", err.response);
    } finally {
      setLoading(false);
    }
  };

  return { setPaymentMethod, loading, error };
};
