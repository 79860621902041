import CommentList from "components/CommentList";
import React from "react";
import { useQuery } from "react-query";
import { Link, useParams } from "react-router-dom";
import AddComment from "../components/AddComment";
import { DislikeIcon, LikeIcon } from "../components/Icons";
import NoResults from "../components/NoResults";
import VideoCard from "../components/VideoCard";
import VideoPlayer from "../components/VideoPlayer";
import useAuthAction from "../hooks/use-auth-action";
import Skeleton from "../skeletons/WatchVideoSkeleton";
import Button from "../styles/Button";
import Wrapper from "../styles/WatchVideo";
import {
  client,
  dislikeVideo,
  likeVideo,
  toggleSubscribeUser,
} from "../utils/api-client";
import { formatCreatedAt } from "../utils/date";

function WatchVideo() {
  const { videoId } = useParams();
  const handleAuthAction = useAuthAction();
  const { data: video, isLoading: isLoadingVideo } = useQuery(
    ["WatchVideo", videoId],
    () => client.get(`/videos/${videoId}`).then((res) => res.data)
  );
  const { data: next, isLoading: isLoadingNext } = useQuery(
    ["WatchVideo", "Up Next"],
    () => client.get("/videos").then((res) => res.data.videos)
  );

  if (isLoadingVideo || isLoadingNext) {
    return <Skeleton />;
  }
  if (!isLoadingVideo && !video) {
    return (
      <NoResults
        title="Page not found"
        text="The page you are looking for is not found or it may have been removed"
      />
    );
  }

  function handleLikeVideo() {
    handleAuthAction(likeVideo, video?.video?.id);
  }

  function handleDislikeVideo() {
    handleAuthAction(dislikeVideo, video?.video?.id);
  }

  function handleToggleSubscribe() {
    handleAuthAction(toggleSubscribeUser, video?.video?.user.id);
  }

  // console.log("Video", video);
  // console.log("VideoVideo", video?.video);

  return (
    <Wrapper
      // @ts-ignore
      filledLike={video && video.isLiked}
      filledDislike={video && video.isDisliked}
    >
      <div className="video-container">
        <div className="video">
          {!isLoadingVideo && (
            <VideoPlayer video={video?.video} previewUrl={video?.video?.url} />
          )}
        </div>

        <div className="video-info">
          <h3>{video?.video?.title}</h3>
          <h6>
            <span>Generated With {video?.video?.generatedWith}</span>
          </h6>

          <div className="video-info-stats">
            <p>
              <span>{video?.video?._count?.views || 0} views</span>{" "}
              <span>•</span>{" "}
              <span>Premiered {formatCreatedAt(video?.video?.createdAt)}</span>
            </p>

            <div className="likes-dislikes flex-row">
              <p className="flex-row like">
                <LikeIcon
                  style={{
                    fill: video?.video?.isLiked ? "#4bd6e5" : undefined,
                  }}
                  onClick={handleLikeVideo}
                />{" "}
                <span>{video?.video?.likesCount}</span>
              </p>
              <p className="flex-row dislike" style={{ marginLeft: "1rem" }}>
                <DislikeIcon
                  onClick={handleDislikeVideo}
                  style={{
                    fill: video?.video?.isDisliked ? "#4bd6e5" : undefined,
                  }}
                />{" "}
                <span>{video?.video?.dislikesCount}</span>
              </p>
            </div>
          </div>
        </div>

        <div className="channel-info-description">
          <div className="channel-info-flex">
            <div className="channel-info flex-row">
              <img
                className="avatar md"
                src={video?.video?.user.avatar}
                alt={`${video?.video?.user.username} channel avatar`}
              />
              <div className="channel-info-meta">
                <h4>
                  <Link to={`/channel/${video?.video?.user.id}`}>
                    {video?.video?.user.username}
                  </Link>
                </h4>
                <span className="secondary small">
                  {video?.video?.subscribersCount} Followers
                </span>
              </div>
            </div>

            {!video?.video?.isVideoMine && !video?.video?.isSubscribed && (
              <Button onClick={handleToggleSubscribe}>Follow</Button>
            )}
            {!video?.video?.isVideoMine && video?.video?.isSubscribed && (
              <Button onClick={handleToggleSubscribe}>Following</Button>
            )}
          </div>

          <p>{video?.video?.description}</p>
        </div>

        <AddComment video={video?.video} />

        <CommentList comments={video?.video?.comments} />
      </div>

      <div className="related-videos">
        <h3 className="up-next">Up Next</h3>
        {next
          .filter((v) => v.id !== video?.video.id)
          .slice(0, 10)
          .map((video) => (
            <VideoCard
              key={video.id}
              hideAvatar
              video={video}
              noUsername={undefined}
            />
          ))}
      </div>
    </Wrapper>
  );
}

export default WatchVideo;
