import styled from "styled-components";

const Container = styled.div`
  margin-top: 60px;
  margin-left: 240px;
  @media screen and (max-width: 500px) {
    margin-top: 76px;
  }
  @media screen and (max-width: 1093px) {
    margin-left: 0;
  }
`;

export default Container;
