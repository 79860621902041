import { GoogleLogin } from "@react-oauth/google";
import React from "react";
import { authenticate } from "../utils/api-client";

function GoogleAuth() {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        maxWidth: "180px",
      }}
    >
      <GoogleLogin
        onSuccess={authenticate}
        onError={authenticate}
        size="large"
        text="signin_with"
        auto_select={false}
      />
    </div>
  );
}

export default GoogleAuth;
