import styled from "styled-components";

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 5rem 0;
  margin: 0 auto;
  width: 90%;

h1,
h2,
h3,
h4 {
  text-align: center;
}

`;

export default Wrapper;
