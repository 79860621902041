import styled, { keyframes } from "styled-components";

const volume = keyframes`
    0% {
        width: 0px;
        }
        
        100% {
        width: 100px;
    }
`;

const volumeHide = keyframes`
    0% {
        width: 100px;
        }
        
        100% {
            width: 0px;
    }
`;

const comments = keyframes`
    0% {
        transform: translateX(100%);
        }
        
        100% {
            transform: translateX(0px);
        }
`;

const ShortsWrapper = styled.div`
  width: 100%;
  height: calc(100vh - 60px);
  overflow-y: hidden;
  position: relative;
  display: flex;
  justify-content: between;
  .navigators {
    position: absolute;
    right: 20px;
    top: 0;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 20px;
    z-index: 100;
  }
  .short-videos-container {
    gap: 20px;
    width: 90%;
    transition: all 0.3s ease-in-out;
    overflow-x: hidden;
  }

  .short-container {
    width: 400px;
    height: 100%;
    margin: 0 auto;
    // background: red;
    padding-top: 30px;
    padding-bottom: 0px;
    scroll-snap-type: y mandatory;
    overflow-y: scroll;
    scrollbar-width: none;
  }

  .short-container::-webkit-scrollbar {
    display: none; /* Hide scrollbar for Webkit browsers */
  }
  .short-video-container {
    width: 100%;
    height: 100%;
    scroll-snap-align: start;
    // margin: 10px 0;
    // background: red;
    padding-top: 30px;
    padding-bottom: 10px;
  }
  .short-video {
    width: 100%;
    height: 100%;
    background: black;
    border-radius: 20px;
    position: relative;
    overflow: hidden;
  }
  .upper-controls {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    padding: 20px 25px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .left-icons {
    display: flex;
    align-items: center;
    gap: 5px;
  }
  .control-icon {
    margin-right: 10px;
    cursor: pointer;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: grid;
    place-items: center;
  }
  .control-icon:hover {
    background: rgba(255, 255, 255, 0.2);
  }
  .volume-control {
    margin-right: 10px;
    cursor: pointer;
    border-radius: 20px;
    padding: 5px 10px;
    display: flex;
    align-items: center;
    gap: 10px;
  }
  .volume-range {
    -webkit-appearance: none;
    width: 100px;
    height: 5px;
    // background: gray; /* Change the background color of the slider track */
    border-radius: 5px;
    outline: none;
  }

  /* Style the slider thumb */
  .volume-range::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 18px;
    height: 18px;
    background: white; /* Change the thumb color */
    border: 2px solid #000;
    border-radius: 50%;
    cursor: pointer;
  }

  .volume-range::-moz-range-thumb {
    width: 18px;
    height: 18px;
    background: white; /* Change the thumb color */
    border: 2px solid #000;
    border-radius: 50%;
    cursor: pointer;
  }

  .volume-range::-ms-thumb {
    width: 18px;
    height: 18px;
    background: white; /* Change the thumb color */
    border: 2px solid #000;
    border-radius: 50%;
    cursor: pointer;
  }
  .volume-control:hover {
    background: rgba(255, 255, 255, 0.2);
  }
  .volume-animation {
    animation: ${volume} 0.1s linear;
    animation-iteration-count: 1;
  }
  .volume-hide-animation {
    animation: ${volumeHide} 0.1s linear;
    animation-iteration-count: 1;
  }

  .right-controls {
    gap: 15px;
    margin-bottom: 20px;
  }
  .control-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  .right-control-icon {
    cursor: pointer;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    display: grid;
    place-items: center;
    background: rgba(255, 255, 255, 0.1);
  }
  .right-control-icon:hover {
    background: rgba(255, 255, 255, 0.2);
  }
  .right-control-icon.active {
    background: rgb(230, 230, 230);
  }
  .right-control-icon.active svg {
    fill: rgb(0, 0, 0);
  }
  .control-container span {
    font-size: 0.9rem;
  }
  .bottom-details {
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 20px 25px;
    margin-bottom: 30px;
  }
  .channel-info {
    gap: 10px;
  }
  .channel-img {
    width: 35px;
    height: 35px;
  }
  .channel-img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
  }
  .channel-name {
    font-size: 1rem;
  }
  .video-caption {
    font-size: 0.9rem;
    margin: 0;
    margin-top: 10px;
    font-weight: 500;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .comment-box {
    width: 400px;
    height: 100%;
    padding: 30px 0;
    overflow: hidden;
  }
  .inner-comment-box {
    width: 100%;
    height: 100%;
    border-radius: 10px;
    border: 1px solid rgba(255, 255, 255, 0.2);
    overflow: hidden;
    position: relative;
  }
  .comments-header {
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    padding: 10px 20px;
  }
  .comments-title {
    gap: 10px;
  }
  .comments-title h3 {
    margin: 0;
    font-size: 1.2rem;
    font-weight: 500;
  }
  .comments-title span {
    font-size: 1rem;
    color: rgba(255, 255, 255, 0.5);
    margin: 0;
  }
  .comments-list {
    overflow-y: scroll;
    height: calc(100% - 130px);

    // custom scrollbar
    &::-webkit-scrollbar {
      width: 8px;
      padding-right: 10px;
    }

    &::-webkit-scrollbar-thumb {
      background: #c2c2c2; /* Color of the thumb */
      border-radius: 10px; /* Rounded corners for the thumb */
    }
  }

  .add-comment {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 10px 20px;
    border-top: 1px solid rgba(255, 255, 255, 0.2);
    display: flex;
    align-items: center;
    gap: 10px;
  }
  .add-comment img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: cover;
  }

  .add-comment textarea {
    background: inherit;
    border: none;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    color: white;
    flex-grow: 1;
    height: 100%;
    padding-bottom: 10px;
  }
  .control-icon.comment-close {
    margin-right: 0;
  }

  @media screen and (min-width: 950px) {
    .comment-box.active {
      animation: ${comments} 0.2s ease forwards;
    }
  }
  @media screen and (max-width: 950px) {
    .comment-box {
      position: absolute;
      bottom: 60px;
      left: 40%;
      transform: translateX(-47%);
      height: calc(100vh - 250px);
      background: rgb(21, 31, 43);
      padding: 0;
      width: 370px;
    }
  }

  @media screen and (max-width: 500px) {
    height: calc(100vh - 134px);
    .short-videos-container {
      width: 100%;
    }
    .short-container {
      width: 100%;
      padding: 0;
      margin: 0;
    }
    .navigators {
      display: none;
    }
    .short-video-container {
      position: relative;
      padding: 0;
      margin: 0;
    }
    .right-controls {
      position: absolute;
      right: 10px;
      bottom: 55px;
    }
    .control-icon {
      display: none;
    }
    .upper-controls {
      padding: 10px;
    }
    .short-video {
      border-radius: 0;
    }
    .right-control-icon {
      width: 40px;
      height: 40px;
    }
    .volume-range {
      display: none;
    }
    .comment-box {
      width: 100%;
      left: 0;
      transform: translateX(0);
      bottom: 0;
    }
    .inner-comment-box {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
    .comment-close {
      display: grid;
    }
  }
`;

export default ShortsWrapper;
