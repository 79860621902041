import React, { useEffect, useState } from "react";
import styled, { keyframes } from "styled-components";
import { CiPlay1 } from "react-icons/ci";

// Keyframe Animations
const pulse = keyframes`
  0%, 100% { opacity: 1; }
  50% { opacity: 0.5; }
`;

const ping = keyframes`
  75%, 100% {
    transform: scale(2);
    opacity: 0;
  }
`;

// Styled Components
const Card = styled.div`
  width: 100%;
  max-width: 100%;
  margin: 0 auto;
  background: white;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  overflow: hidden;
`;

const VideoContainer = styled.div`
  position: relative;
  aspect-ratio: 16/9;
  background: rgb(75, 75, 75);
`;

const GradientOverlay = styled.div`
  position: absolute;
  inset: 0;
  background: linear-gradient(
    to right,
    rgb(129, 129, 129),
    rgb(129, 129, 129),
    rgb(129, 129, 129)
  );
  animation: ${pulse} 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
`;

const CenterContainer = styled.div`
  position: absolute;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const PulseRing = styled.div`
  position: absolute;
  inset: -16px;
  background: rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  animation: ${ping} 1s cubic-bezier(0, 0, 0.2, 1) infinite;
`;

const PlayButton = styled.div`
  position: relative;
  background: rgba(255, 255, 255, 0.9);
  padding: 16px;
  border-radius: 50%;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  display: grid;
  place-items: center;
  width: 50px;
  height: 50px;
`;

const ProgressBarContainer = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 4px;
  background: #e5e7eb;
`;

const ProgressBar = styled.div`
  height: 100%;
  background: #3b82f6;
  width: ${(props) => props.width};
  transition: width 0.3s ease-in-out;
`;

const loadingProgress = keyframes`
  0% { width: 0%; }
  50% { width: 70%; }
  100% { width: 100%; }
`;

const ContentContainer = styled.div`
  padding: 16px;
`;

const LoadingText = styled.div`
  height: ${(props) => props.height || "16px"};
  width: ${(props) => props.width || "75%"};
  background: #e5e7eb;
  border-radius: 4px;
  margin-bottom: ${(props) => props.marginBottom || "8px"};
  animation: ${pulse} 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
`;

const LoadingAnimation = ({ progress }) => {
  return (
    <Card>
      <VideoContainer>
        <GradientOverlay />
        <CenterContainer>
          <div style={{ position: "relative" }}>
            <PulseRing />
            <PlayButton>
              <CiPlay1 size={32} style={{ margin: 0 }} color="#1f2937" />
            </PlayButton>
          </div>
        </CenterContainer>
        <ProgressBarContainer>
          <ProgressBar width={`${progress}%`} />
        </ProgressBarContainer>
      </VideoContainer>
      {/* <ContentContainer>
        <LoadingText height="16px" width="75%" marginBottom="8px" />
        <LoadingText height="12px" width="50%" />
      </ContentContainer> */}
    </Card>
  );
};

export default LoadingAnimation;
