import React from "react";
import videojs from "video.js";
import "video.js/dist/video-js.css";
import { addVideoView } from "../utils/api-client";

function VideoPlayer({ previewUrl, video, videoView = true }) {
  const videoRef = React.useRef();

  const { id, url, thumbnail } = video;

  React.useEffect(() => {
    const vjsPlayer = videojs(videoRef.current, {
      responsive: true,
      fluid: true, // Ensures the video player adjusts to its container
      aspectRatio: "16:9", // Default aspect ratio; adjust if needed
    });

    if (!previewUrl) {
      vjsPlayer.poster(thumbnail);
      vjsPlayer.src({ type: "video/mp4", src: url });
    } else {
      vjsPlayer.src({ type: "video/mp4", src: previewUrl });
    }

    vjsPlayer.on("ended", () => {
      if (!videoView) return;
      addVideoView(id);
    });

    return () => {
      if (vjsPlayer) {
        vjsPlayer.dispose();
      }
    };
  }, [id, thumbnail, url, previewUrl]);

  return (
    <div data-vjs-player style={{ maxHeight: "75vh", overflow: "hidden" }}>
      <video
        controls
        ref={videoRef}
        className="video-js vjs-big-play-centered"
        style={{ maxHeight: "100%", objectFit: "contain" }}
      ></video>
    </div>
  );
}

export default VideoPlayer;
