export const darkTheme = {
  // bg: "#181818",
  bg: "#151F2B",
  primaryColor: "#FFF",
  secondaryColor: "#AAAAAA",
  thirdColor: "#38444D",
  fourthColor: "#8898A6",
  grey: "#202020",
  lightNavyBlue: "#263341",
  navyBlue: "#1A2734",
  darkGrey: "#383838",
  //  darkNavyBlue: "#151F2B",
  black: "#121212",
  red: "#cc0a00",
  // blue: "#3EA6FF",
  // blue: "#1C9CEF",
  blue: "#2B8AFF",
  cyan: "#4bd6e5",
  white: "#FFF",
  yellow: "#FBF302",
  pink: "#F86782",
  purple: "#282A36",
  font: "'Roboto', Arial, sans-serif",
};
