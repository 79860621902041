import {
  FacebookIcon,
  InstagramIcon,
  LinkedInIcon,
  PinterestIcon,
  TiktokIcon,
  TwitterIcon,
} from "components/Icons";
import React from "react";
import iconEmail from "../assets/img/svg/Email_Us.svg";
import "../styles/CustomStyle.css";
import Wrapper from "styles/ContactUs";

function ContactUs() {
  return (
    <Wrapper>
      <section className="slice slice-lg">
        <div className="container">
          <div className="row mb-5 justify-content-center text-center">
            <div className="col-lg-8 col-md-10">
              <h2 className="mt-4">Need personal assistance?</h2>
              <div className="mt-2">
                <p className="lead lh-180">
                  Want to share any feedback with us, report a technical issue
                  or just ask us a question? Feel free to contact us and we will
                  get back to you shortly.
                </p>
              </div>
            </div>
          </div>
          <div className="row justify-content-center">
            {/* <div className="col-md-5">
                    <div className="card">
                        <div className="card-body p-5 text-center">
                            <div className="pb-4">
                                <img src={iconCallOut} alt="Phone illustration" className="svg-inject img-fluid" style={{height: 40}} />
                            </div>
                            <h5>+1 (646) 600-9191</h5>
                            <p className="mt-2 mb-0">
                                Our support team is here for you.
                            </p>
                        </div>
                    </div>
                </div> */}
            <div className="col-md-5">
              <div className="card">
                <div className="card-body p-5 text-center">
                  <div className="pb-4">
                    <img
                      src={iconEmail}
                      alt="Email illustration"
                      className="svg-inject img-fluid"
                      style={{ height: 40 }}
                    />
                  </div>
                  <h5>
                    <a href="mailto:support@aivideostube.com">
                      support@aivideostube.com
                    </a>
                  </h5>
                  <p className="mt-2 mb-0">
                    You'll get a reply in 3 to 12 hours.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="social-icons">
            <a
              href="https://www.facebook.com/AIVideosTube"
              target="_blank"
              rel="noopener noreferrer"
              style={{ display: "inline-block" }}
            >
              <FacebookIcon height="44" width="44" />
            </a>

            <a
              href="https://www.tiktok.com"
              target="_blank"
              rel="noopener noreferrer"
              style={{ display: "inline-block" }}
            >
              <TiktokIcon height="50" width="50" />
            </a>
            <a
              href="https://www.pinterest.com"
              target="_blank"
              rel="noopener noreferrer"
              style={{ display: "inline-block" }}
            >
              <PinterestIcon height="44" width="44" />
            </a>
            <a
              href="https://www.linkedin.com"
              target="_blank"
              rel="noopener noreferrer"
              style={{ display: "inline-block" }}
            >
              <LinkedInIcon height="40" width="40" />
            </a>
            <a
              href="https://twitter.com"
              target="_blank"
              rel="noopener noreferrer"
              style={{ display: "inline-block" }}
            >
              <TwitterIcon height="44" width="44" />
            </a>
            <a
              href="https://www.instagram.com"
              target="_blank"
              rel="noopener noreferrer"
              style={{ display: "inline-block" }}
            >
              <InstagramIcon height="44" width="44" />
            </a>
          </div>
        </div>
      </section>
    </Wrapper>
  );
}

export default ContactUs;
