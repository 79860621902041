import { useState } from "react";
import { client } from "utils/api-client";

export const useLogin = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const login = async (email, password) => {
    try {
      setLoading(true);
      setError(null);

      const res = await client.post("/auth/login", { email, password });

      const token = res?.data?.token;
      localStorage.setItem("token", token);
      localStorage.setItem("authUser", JSON.stringify(res.data.user));
      window.location.assign("/");
    } catch (err) {
      setError(err.response?.data?.message || "An error occurred");
      console.error("Login error:", err.response);
    } finally {
      setLoading(false);
    }
  };

  return { login, loading, error };
};
