import React from "react";
import { Route, Switch } from "react-router-dom";
import MobileNavbar from "./components/MobileNavbar";
import Navbar from "./components/Navbar";
import Sidebar from "./components/Sidebar";
import { useLocationChange } from "./hooks/use-location-change";
import Channel from "./pages/Channel";
import History from "./pages/History";
import Home from "./pages/Home";
import Library from "./pages/Library";
import LikedVideos from "./pages/LikedVideos";
import NotFound from "./pages/NotFound";
import SearchResults from "./pages/SearchResults";
import Subscriptions from "./pages/Subscriptions";
import Trending from "./pages/Trending";
import WatchVideo from "./pages/WatchVideo";
import YourVideos from "./pages/YourVideos";
import HowItWorks from "pages/HowItWorks";
import AboutUs from "pages/AboutUs";
import ContactUs from "pages/ContactUs";
import PrivacyPolicy from "pages/PrivacyPolicy";
import TermsService from "pages/TermsService";
import Container from "./styles/Container";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { googleClientId } from "config/config";
import ReactGA from "react-ga4";
import ScrollToTop from "components/ScrollToTop";
import SubscriptionPlans from "pages/Plans";
import ShortsPage from "pages/Shorts";
import Profile from "pages/Profile";

ReactGA.initialize("G-8ZFJYHJV17");

function App() {
  const [isSidebarOpen, setSidebarOpen] = React.useState(false);
  const handleCloseSidebar = () => setSidebarOpen(false);
  const toggleSidebarOpen = () => setSidebarOpen(!isSidebarOpen);
  useLocationChange(handleCloseSidebar);

  useLocationChange(() => {
    ReactGA.send("pageview");
  });

  return (
    <>
      <GoogleOAuthProvider clientId={googleClientId}>
        <Navbar toggleSidebarOpen={toggleSidebarOpen} />
        <Sidebar
          isSidebarOpen={isSidebarOpen}
          closeSidebar={handleCloseSidebar}
        />
        <MobileNavbar />
        <Container>
          <ScrollToTop />
          <Switch>
            <Route exact path="/" component={Home} />
            <Route path="/plans" component={SubscriptionPlans} />
            <Route path="/watch/:videoId" component={WatchVideo} />
            <Route path="/channel/:channelId" component={Channel} />
            <Route path="/shorts/:id" component={ShortsPage} />
            <Route path="/shorts" component={ShortsPage} />
            <Route path="/profile" component={Profile} />
            <Route path="/results/:searchQuery" component={SearchResults} />
            <Route path="/feed/trending" component={Trending} />
            <Route path="/feed/subscriptions" component={Subscriptions} />
            <Route path="/feed/library" component={Library} />
            <Route path="/feed/history" component={History} />
            <Route path="/feed/my_videos" component={YourVideos} />
            <Route path="/feed/liked_videos" component={LikedVideos} />
            <Route path="/feed/how_works" component={HowItWorks} />
            <Route path="/feed/about" component={AboutUs} />
            <Route path="/feed/contact" component={ContactUs} />
            <Route path="/feed/terms_service" component={TermsService} />
            <Route path="/feed/privacy_policy" component={PrivacyPolicy} />
            <Route path="*" component={NotFound} />
          </Switch>
        </Container>
      </GoogleOAuthProvider>
    </>
  );
}

export default App;
