import React from "react";
import { MdOutlineStar } from "react-icons/md";
import ProfileWrapper from "styles/Profile";
import { HiOutlineDotsHorizontal } from "react-icons/hi";

const Profile = () => {
  return (
    <ProfileWrapper>
      <h3 className="profile-title">Profile</h3>
      <div className="d-flex profile-container align-items-start">
        <div className="profile-plan-info">
          <div className="plan-info">
            <div className="d-flex plan-info-header align-items-center">
              <div className="header-badge">
                <MdOutlineStar fill="black" size={24} />
              </div>
              <div className="plan-name-price">
                <h3 className="plan-name">Pro</h3>
                <p className="plan-price">$19.99/month</p>
              </div>
            </div>
            <div className="plan-info-footer">
              <h3 className="info-footer-title">Subscribed Plan</h3>
              <span>(12 Days Remaining)</span>
            </div>
          </div>
          <div className="plan-info-bottom d-flex flex-column">
            <div className="d-flex plan-key-value justify-content-between align-items-center w-100">
              <p className="plan-info-key">Plan</p>
              <h4 className="plan-info-value">Premium</h4>
            </div>
            <div className="d-flex plan-key-value justify-content-between align-items-center w-100">
              <p className="plan-info-key">Monthly Credit</p>
              <h4 className="plan-info-value">400</h4>
            </div>
            <div className="d-flex plan-key-value justify-content-between align-items-center w-100">
              <p className="plan-info-key">Non-Expired Credit</p>
              <h4 className="plan-info-value">100</h4>
            </div>
            <div className="d-flex plan-key-value justify-content-between align-items-center w-100">
              <p className="plan-info-key">Billing Name</p>
              <h4 className="plan-info-value">Laoshe King</h4>
            </div>
            <div className="d-flex plan-key-value justify-content-between align-items-center w-100">
              <p className="plan-info-key">Default Payment Method</p>
              <h4 className="plan-info-value">**** **** **** 9908</h4>
            </div>
          </div>
          <div className="cancel-container">
            <p className="cancel-text">Cancel Plan</p>
          </div>
        </div>
        <div className="profile-plan-info profile-history">
          <h4 className="profile-history-title">Generated Videos</h4>
          <div className="profile-history-navbar">
            <p className="history-nav-btn active">Videos</p>
            <p className="history-nav-btn">Transation History</p>
          </div>
          <div className="generated-videos-container">
            <div className="generated-video">
              <div className="video-left-side">
                <img
                  src="https://res.cloudinary.com/dktyndov2/video/upload/v1737725465/e6mry93ulkef3xhq48tr.jpg"
                  className="video-thumbnail"
                  alt=""
                />
                <div className="video-info d-flex flex-column justify-content-between">
                  <h4 className="video-title">War | Battle Royale</h4>
                  <p className="video-description">15 days ago</p>
                  <p className="video-description">
                    This video is generated by Runway Ai Model
                  </p>
                </div>
              </div>
              <div className="right-video-status d-flex align-items-center">
                <p className="video-status active">Draft</p>
                <HiOutlineDotsHorizontal />
              </div>
            </div>
            <div className="generated-video">
              <div className="video-left-side">
                <img
                  src="https://res.cloudinary.com/dktyndov2/video/upload/v1737725465/e6mry93ulkef3xhq48tr.jpg"
                  className="video-thumbnail"
                  alt=""
                />
                <div className="video-info d-flex flex-column justify-content-between">
                  <h4 className="video-title">War | Battle Royale</h4>
                  <p className="video-description">15 days ago</p>
                  <p className="video-description">
                    This video is generated by Runway Ai Model
                  </p>
                </div>
              </div>
              <div className="right-video-status d-flex align-items-center">
                <p className="video-status active">Draft</p>
                <HiOutlineDotsHorizontal />
              </div>
            </div>
            <div className="generated-video">
              <div className="video-left-side">
                <img
                  src="https://res.cloudinary.com/dktyndov2/video/upload/v1737725465/e6mry93ulkef3xhq48tr.jpg"
                  className="video-thumbnail"
                  alt=""
                />
                <div className="video-info d-flex flex-column justify-content-between">
                  <h4 className="video-title">War | Battle Royale</h4>
                  <p className="video-description">15 days ago</p>
                  <p className="video-description">
                    This video is generated by Runway Ai Model
                  </p>
                </div>
              </div>
              <div className="right-video-status d-flex align-items-center">
                <p className="video-status active">Draft</p>
                <HiOutlineDotsHorizontal />
              </div>
            </div>
            <div className="generated-video">
              <div className="video-left-side">
                <img
                  src="https://res.cloudinary.com/dktyndov2/video/upload/v1737725465/e6mry93ulkef3xhq48tr.jpg"
                  className="video-thumbnail"
                  alt=""
                />
                <div className="video-info d-flex flex-column justify-content-between">
                  <h4 className="video-title">War | Battle Royale</h4>
                  <p className="video-description">15 days ago</p>
                  <p className="video-description">
                    This video is generated by Runway Ai Model
                  </p>
                </div>
              </div>
              <div className="right-video-status d-flex align-items-center">
                <p className="video-status active">Draft</p>
                <HiOutlineDotsHorizontal />
              </div>
            </div>
          </div>
        </div>
      </div>
    </ProfileWrapper>
  );
};

export default Profile;
