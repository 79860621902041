import styled from "styled-components";

const Wrapper = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  text-align: center;

  img {
    width: 300px;
    height: 200px;
  }
`;

export default Wrapper;
