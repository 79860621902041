import React from "react";
import Wrapper from "../styles/TermsService";

function TermsService() {
  return (
    <Wrapper>
      <h1>Terms and Conditions</h1>
      <p className="last-updated">Last updated: January 1, 2025</p>
      <p>
        Please read these terms and conditions carefully before using our
        Service.
      </p>

      <h1>Interpretation and Definitions</h1>
      <h2>Interpretation</h2>
      <p>
        The words with initial capitalized letters have meanings defined under
        the following conditions. These definitions shall have the same meaning
        regardless of whether they appear in singular or plural form.
      </p>

      <h2>Definitions</h2>
      <p>For the purposes of these Terms and Conditions:</p>
      <ul>
        <li>
          <strong>Application:</strong> The software program provided by the
          Company, downloaded on any electronic device, named AITube.
        </li>
        <li>
          <strong>Application Store:</strong> The digital distribution platform
          where the Application is downloaded, such as Apple App Store or Google
          Play Store.
        </li>
        <li>
          <strong>Affiliate:</strong> An entity controlling, controlled by, or
          under common control with a party, where "control" means ownership of
          50% or more of voting rights.
        </li>
        <li>
          <strong>Account:</strong> A unique account created for You to access
          our Service.
        </li>
        <li>
          <strong>Country:</strong> Refers to New York, United States.
        </li>
        <li>
          <strong>Company:</strong> Refers to AITube, Inc, located on New York,
          New York ("we," "us," "our").
        </li>
        <li>
          <strong>Content:</strong> Any text, images, or other information
          posted or made available by You via the Service.
        </li>
        <li>
          <strong>Device:</strong> Any device, such as a computer, smartphone,
          or tablet, that can access the Service.
        </li>
        <li>
          <strong>Feedback:</strong> Suggestions or ideas provided by You
          regarding the Service.
        </li>
        <li>
          <strong>Service:</strong> Refers to the Application.
        </li>
        <li>
          <strong>Third-party Social Media Service:</strong> Services or content
          provided by third parties integrated with the Service.
        </li>
        <li>
          <strong>You:</strong> The individual or entity accessing or using the
          Service.
        </li>
      </ul>

      <h1>Acknowledgment</h1>
      <p>
        These Terms and Conditions govern the use of our Service and set out the
        rights and obligations of all users. By accessing or using the Service,
        You agree to these Terms. If You do not agree, You may not access the
        Service.
      </p>
      <p>
        You must be at least 18 years old to use the Service. By using the
        Service, You confirm that You meet this age requirement.
      </p>
      <p>
        Your use of the Service is also subject to our{" "}
        <a href="/privacy-policy">Privacy Policy</a>, which explains how we
        collect and handle Your personal information.
      </p>

      <h1>User Accounts</h1>
      <p>
        You are responsible for maintaining the confidentiality of Your account
        and password and for restricting access to Your account. Providing false
        or inaccurate information constitutes a breach of these Terms, and we
        may terminate Your account immediately.
      </p>

      <h1>Content</h1>
      <h2>Your Right to Post Content</h2>
      <p>
        By posting Content to the Service, You grant us the right to use,
        display, modify, and distribute it. You retain ownership of your Content
        and must ensure it does not violate any laws or third-party rights.
      </p>

      <h2>Content Restrictions</h2>
      <p>Prohibited content includes, but is not limited to:</p>
      <ul>
        <li>Illegal or harmful material.</li>
        <li>Discriminatory or offensive content.</li>
        <li>Spam, viruses, or unauthorized advertising.</li>
        <li>Content infringing on copyrights, trademarks, or other rights.</li>
        <li>False information or impersonation of others.</li>
      </ul>
      <p>
        We reserve the right to remove inappropriate content and may suspend or
        terminate accounts that violate these restrictions.
      </p>

      <h1>Copyright Policy</h1>
      <p>
        If You believe content on the Service infringes Your copyright, please
        notify us at{" "}
        <a href="mailto:legal@aivideostube.com">legal@aivideostube.com</a>. Your
        notification must comply with the Digital Millennium Copyright Act
        (DMCA).
      </p>

      <h1>Intellectual Property</h1>
      <p>
        The Service and its original content (excluding user-provided Content)
        are the exclusive property of the Company. Unauthorized use of our
        intellectual property is prohibited.
      </p>

      <h1>Links to Other Websites</h1>
      <p>
        We are not responsible for third-party websites linked through our
        Service. You access these sites at your own risk and should review their
        terms and privacy policies.
      </p>

      <h1>Termination</h1>
      <p>
        We may terminate Your account for breach of these Terms. Upon
        termination, You lose access to the Service immediately.
      </p>

      <h1>Limitation of Liability</h1>
      <p>
        Our liability is limited to the amount paid by You through the Service
        or $100, whichever is greater. We are not liable for indirect,
        incidental, or consequential damages.
      </p>

      <h1>Disclaimer</h1>
      <p>
        The Service is provided "AS IS" and "AS AVAILABLE" without warranties of
        any kind. Some jurisdictions may not allow certain warranty exclusions.
      </p>

      <h1>Governing Law</h1>
      <p>
        These Terms are governed by the laws of New York, United States, without
        regard to conflict of law principles.
      </p>

      <h1>Changes to These Terms and Conditions</h1>
      <p>
        We reserve the right to modify these Terms at any time. We will notify
        You of material changes 30 days before they take effect. Continued use
        of the Service constitutes Your acceptance of the updated Terms.
      </p>

      <h1>Contact Us</h1>
      <p>If you have questions about these Terms, you can contact us at:</p>
      <ul>
        <li>
          Email:{" "}
          <a href="mailto:office@aivideostube.com">office@aivideostube.com</a>
        </li>
      </ul>
    </Wrapper>
  );
}

export default TermsService;
