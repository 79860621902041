import React from "react";
import GenerateVideoModal from "./GenerateVideoModal";
import GenerateIcon from "../assets/video.png";

function GenerateVideo() {
  const [showModal, setShowModal] = React.useState(false);

  const closeModal = () => {
    setShowModal(false);
  };

  return (
    <div>
      <div onClick={() => setShowModal(true)}>
        <img
          style={{ width: "25px" }}
          className="mb-1 cursor-pointer"
          src={GenerateIcon}
          alt=""
        />
      </div>
      {showModal && <GenerateVideoModal closeModal={closeModal} />}
    </div>
  );
}

export default GenerateVideo;
