import styled from "styled-components";

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  font-family: "Arial", sans-serif;
`;

const Title = styled.h1`
  font-size: 2.5rem;
  color: #fff;
  margin-bottom: 20px;
`;

const CardsContainer = styled.div`
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 1200px;
`;

const Card = styled.div`
  background: rgb(38, 51, 65);
  border: 1px solid rgb(75, 214, 229);
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  width: 350px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-align: center;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const PlanTitle = styled.h2`
  font-size: 1.8rem;
  color: rgb(75, 214, 229);
  margin-bottom: 10px;
`;

const Price = styled.p`
  font-size: 1.2rem;
  font-weight: bold;
  color: #fff;
  margin-bottom: 20px;
`;

const ServicesList = styled.ul`
  padding: 0 10px;
  margin-bottom: 20px;
  list-style: disc inside none;
  list-style-position: outside;
  .m-0 {
    margin-bottom: 0;
  }
`;

const ServiceItem = styled.li`
  font-size: 1rem;
  color: #e0e0e0;
  text-align: start;
  margin: 8px 0;
  .ml-1 {
    margin-left: 5px;
  }
  .mr-1 {
    margin-right: 5px;
  }
  span {
    font-size: 0.85rem;
  }
`;

const SubscribeButton = styled.button`
  background-color: rgb(75, 214, 229);
  color: black;
  border: none;
  border-radius: 4px;
  padding: 10px 20px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s;
  &:hover {
    background-color: rgb(43 148 159);
  }
  &:disabled {
    background-color: rgb(8, 113, 124);
    color: #d2d2d2;
    cursor: default;
  }
`;

export {
  PageContainer,
  Title,
  CardsContainer,
  Card,
  PlanTitle,
  Price,
  ServicesList,
  ServiceItem,
  SubscribeButton,
};
