import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useSnackbar } from "react-simple-snackbar";
import { useAuth } from "../context/auth-context";
import Button from "../styles/Button";
import Wrapper from "../styles/UploadVideoModal";
import { addVideo } from "../utils/api-client";
import { CloseIcon } from "./Icons";
import VideoPlayer from "./VideoPlayer";
import { categories } from "config/config";

function UploadVideoModal({
  previewVideo,
  thumbnail,
  url,
  defaultTitle,
  closeModal,
  modalTitle = "Video Uploaded!",
  type = "normal",
}) {
  const user = useAuth();
  const history = useHistory();
  const [openSnackbar] = useSnackbar();
  const [tab, setTab] = React.useState("PREVIEW");
  const [title, setTitle] = React.useState(defaultTitle);
  const [generatedWith, setGeneratedWith] = React.useState("");
  const [category, setCategory] = React.useState("");
  const [otherTool, setOtherTool] = React.useState("");
  const [description, setDescription] = React.useState("");

  async function handleTab() {
    if (tab === "PREVIEW") {
      setTab("FORM");
    } else {
      if (
        !title.trim() ||
        !description.trim() ||
        !category.trim() ||
        (!generatedWith.trim() && !otherTool.trim())
      ) {
        return openSnackbar("Please fill in all the fields");
      }

      const video = {
        title,
        description,
        url,
        thumbnail,
        category,
        generatedWith: generatedWith === "Other" ? otherTool : generatedWith,
      };
      console.log("Video", video);
      await addVideo(video);
      localStorage.removeItem("video_uuid");
      closeModal();
      openSnackbar("Video published!");
      history.push(`/channel/${user.id}`);
    }
  }

  const handleDelete = () => {
    localStorage.removeItem("video_uuid");
    closeModal();
  };

  return (
    <Wrapper>
      <div className="modal-content">
        <div className="modal-header">
          <div className="modal-header-left">
            <CloseIcon onClick={closeModal} />
            <h3>{url ? modalTitle : "Uploading..."}</h3>
          </div>
          <div style={{ display: url ? "block" : "none" }}>
            {type === "generated" && (
              <Button
                style={{
                  backgroundColor: "transparent",
                  border: "1px solid rgb(75, 214, 229)",
                  color: "rgb(75, 214, 229)",
                }}
                onClick={handleDelete}
              >
                Delete
              </Button>
            )}
            <Button onClick={handleTab}>
              {tab === "PREVIEW" ? "Next" : "Upload"}
            </Button>
          </div>
        </div>

        {tab === "PREVIEW" && (
          <div className="tab video-preview">
            <VideoPlayer
              previewUrl={previewVideo}
              video={url}
              videoView={false}
            />
          </div>
        )}

        {tab === "FORM" && (
          <div className="tab video-form">
            <h2>Video Details</h2>
            <input
              type="text"
              placeholder="Enter your video title"
              value={title}
              onChange={(event) => setTitle(event.target.value)}
            />
            <select
              value={generatedWith}
              onChange={(event) => setGeneratedWith(event.target.value)}
            >
              <option value="">Select AI Model Used</option>
              <option value="AITube/Runway">AITube/Runway</option>
              <option value="Sora-OpenAI">Sora-OpenAI</option>
              <option value="Pictory">Pictory</option>
              <option value="Synthesia">Synthesia</option>
              <option value="DeepBrain AI">DeepBrain AI</option>
              <option value="Elai">Elai</option>
              <option value="Other">Other</option>
            </select>
            {generatedWith === "Other" && (
              <input
                type="text"
                placeholder="Enter the name of the tool"
                value={otherTool}
                onChange={(event) => setOtherTool(event.target.value)}
              />
            )}
            <select
              value={category}
              onChange={(event) => setCategory(event.target.value)}
            >
              <option value="">Select Video Category</option>
              {categories.map((category) => (
                <option key={category} value={category}>
                  {category}
                </option>
              ))}
            </select>
            {/* <input
              type="text"
              placeholder="Which AI model was used to generate this video"
              value={generatedWith}
              onChange={(event) => setGeneratedWith(event.target.value)}
            /> */}
            <textarea
              placeholder="Tell viewers about your text-to-video prompt"
              value={description}
              onChange={(event) => setDescription(event.target.value)}
            />
          </div>
        )}
      </div>
    </Wrapper>
  );
}

export default UploadVideoModal;
