import { CloseIcon } from "./Icons";
import Button from "styles/Button";
import React, { useEffect, useState } from "react";
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";
import { useAddCard } from "hooks/use-add-card";

const AddNewCard = ({ closeModal }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [paymentDone, setPaymentDone] = useState(false);
  const [name, setName] = useState("");
  const [cardError, setCardError] = useState(null);
  const { error, loading, addCard } = useAddCard();

  useEffect(() => {
    if (paymentDone && !error) {
      setTimeout(() => {
        setPaymentDone(false);
        closeModal(true);
      }, 2000);
    }
  }, [paymentDone, error]);

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return; // Stripe.js has not loaded yet.
    }

    const cardElement = elements.getElement(CardElement);

    // Confirm card payment with Stripe
    const { error: err, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: cardElement,
      billing_details: {
        name,
      },
    });
    await addCard(paymentMethod.id);
    if (err) {
      setCardError(err.message);
    }
    if (!err) {
      setPaymentDone(true);
      // Use paymentMethod.id to confirm payment on your server
    }
  };

  return (
    <div className="edit-profile">
      <div className="modal-header">
        <h3>
          <CloseIcon onClick={() => closeModal(false)} />
          <span>Cards</span>
        </h3>
      </div>
      <div className="pb-1 mx-2">
        <form onSubmit={handleSubmit}>
          <input
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
            placeholder="Cardholder Name"
            style={{
              width: "100%",
              padding: "8px",
              margin: "20px 0",
              marginTop: "0px",
              border: "1px solid #ccc",
              borderRadius: "4px",
              color: "white",
            }}
          />
          <CardElement
            className="pb-1"
            options={{ style: { base: { fontSize: "16px", color: "#fff" } } }}
          />
          <Button
            className="w-full"
            style={{ marginTop: "10px" }}
            type="submit"
            disabled={!stripe || loading || (paymentDone && !error)}
          >
            {loading
              ? "Loading..."
              : paymentDone && !error
              ? "Card Added"
              : "Add Card"}
          </Button>
          {(error || cardError) && (
            <div className="card-error">{error || cardError}</div>
          )}
        </form>
      </div>
    </div>
  );
};

export default AddNewCard;
