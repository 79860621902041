export const googleClientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;

export const categories = [
  "All",
  "Entertainment",
  "Education",
  "Memes",
  "Teen Trends",
  "AI Trailers",
  "AI Ads",
  "Storylines",
  "Short Films",
  "AI Movies",
  "AI Animes",
  "Animation",
  "Gaming",
  "Technology & Innovation",
  "News & Current Events",
  "Sports & Fitness",
  "Lifestyle",
  "Relaxation & Calm",
  "Motivational & Inspirational",
  "Energetic & Fun",
  "Romantic & Heartwarming",
  "Humorous & Light-Hearted",
  "Thought-Provoking",
  "Cooking & Food",
  "DIY & Crafts",
  "Travel & Adventure",
  "Wellness & Self-Care",
  "Kids & Family",
  "Professional Development",
  "Action & Thrill",
  "Drama",
  "Fantasy & Sci-Fi",
  "Horror & Mystery",
  "Pets & Animals",
  "Art & Design",
  "Health & Science",
  "Music & Performance",
  "Culture & History",
  "Trending Now",
  "User Favorites",
  "AI Podcast",
  "Discover Something New",
  "Others",
];
