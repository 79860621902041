import React from "react";
import { Link } from "react-router-dom";
import { formatCreatedAt } from "../utils/date";
import DeleteCommentDropdown from "./DeleteCommentDropdown";
import Wrapper from "styles/CommentList";

function CommentList({ comments, updateCommentsCount = null }) {
  return comments.map((comment, index) => (
    <Comment
      key={index}
      comment={comment}
      updateCommentsCount={updateCommentsCount}
    />
  ));
}

function Comment({ comment, updateCommentsCount = null }) {
  return (
    <Wrapper>
      <div className="comment">
        <Link to={`/channel/${comment.user.id}`}>
          <img
            src={comment.user.avatar}
            alt={`${comment.user.username} avatar`}
          />
        </Link>
        <div className="comment-info" style={{ flex: "1 1 0" }}>
          <p className="secondary">
            <span>
              <Link to={`/channel/${comment.user.id}`} className="user-channel">
                {comment.user.username}
              </Link>
            </span>
            <span style={{ marginLeft: "0.6rem" }}>
              {formatCreatedAt(comment.createdAt)}
            </span>
          </p>
          <p>{comment.text}</p>
        </div>
        <DeleteCommentDropdown
          updateCommentsCount={updateCommentsCount}
          comment={comment}
        />
      </div>
    </Wrapper>
  );
}

export default React.memo(CommentList);
