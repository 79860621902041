import { Menu, MenuButton, MenuItem, MenuList } from "@reach/menu-button";
import "@reach/menu-button/styles.css";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import Avatar from "../styles/Avatar";
import { ChannelIcon, SignoutIcon, SubscriptionsIcon } from "./Icons";
import { signoutUser } from "../utils/api-client";
import TextAvatar from "styles/TextAvatar";
import { CiCreditCard1 } from "react-icons/ci";
import ViewCardsModal from "./ViewCardsModal";
import { FaUser } from "react-icons/fa6";
import { FaUserCircle } from "react-icons/fa";
function UserDropdown({ user }) {
  const [showCards, setShowCards] = useState(false);
  const history = useHistory();

  return (
    <Menu>
      <MenuButton>
        {user.avatar ? (
          <Avatar className="pointer" src={user.avatar} alt={user.username} />
        ) : (
          <TextAvatar>{user.username}</TextAvatar>
        )}
      </MenuButton>
      <MenuList>
        <MenuItem onSelect={() => history.push(`/channel/${user.id}`)}>
          <ChannelIcon />
          <span>Your channel</span>
        </MenuItem>
        <MenuItem onSelect={() => history.push("/profile")}>
          <FaUserCircle fill="rgba(255, 255, 255, 0.6)" />
          <span>Profile</span>
        </MenuItem>
        <MenuItem onSelect={() => setShowCards(true)}>
          <CiCreditCard1 size={22} />
          <span>Cards</span>
        </MenuItem>
        <MenuItem onSelect={() => history.push("/plans")}>
          <SubscriptionsIcon />
          <span>Plans</span>
        </MenuItem>
        <MenuItem onSelect={signoutUser}>
          <SignoutIcon />
          <span>Sign out</span>
        </MenuItem>
      </MenuList>
      {showCards && <ViewCardsModal closeModal={() => setShowCards(false)} />}
    </Menu>
  );
}

export default UserDropdown;
