import { useStripe } from "@stripe/react-stripe-js";
import { useState } from "react";
import { queryCache } from "react-query";
import { client } from "utils/api-client";
export const useBuyCredits = () => {
  const stripe = useStripe();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const buyCredits = async () => {
    try {
      setLoading(true);
      setError(null);

      const { data: subscription } = await client.post("/payments/pay-per-use");
      if (
        subscription.status === "incomplete" ||
        subscription.status === "requires_payment_method"
      ) {
        const { client_secret } = subscription.latest_invoice.payment_intent;
        const { error } = await stripe.confirmCardPayment(client_secret);

        if (error) {
          console.error("Payment confirmation error:", error);
          throw new Error("Payment confirmation failed");
        } else {
          console.log("Payment successful");
          return { success: true };
        }
      } else {
        console.log("Subscription created or updated successfully");
        return { success: true };
      }
    } catch (err) {
      setError(err?.response || "An error occurred");
      console.error("Plan Subscription error:", err.response);
      return { success: false };
    } finally {
      setLoading(false);
    }
  };

  return { buyCredits, loading, error };
};
