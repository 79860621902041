import styled from "styled-components";

const Wrapper = styled.div`
  .thumb {
    width: 100%;
    height: 180px;
    object-fit: cover;
    box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2);
    border-radius: 4px;
  }

  .truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .leading-4 {
    line-height: 1;
  }

  .video-info-container {
    display: flex;
    margin-top: 0.6rem;
  }

  .channel-avatar img {
    position: relative;
    top: 5px;
  }

  .video-info {
    flex: 1 1 0;
  }

  .video-info span {
    font-size: 0.9rem;
    padding-right: 0.1rem;
  }

  @media screen and (max-width: 600px) {
    .thumb {
      height: 250px;
    }
  }

  @media screen and (max-width: 420px) {
    .thumb {
      height: 200px;
    }
  }
`;

export default Wrapper;
