import { useState } from "react";
import { queryCache } from "react-query";
import { client } from "utils/api-client";
export const useRemoveCard = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const removeCard = async (paymentMethodId) => {
    try {
      setLoading(true);
      setError(null);

      const res = await client.delete(
        `/payments/remove_payment_method/${paymentMethodId}`
      );
      await queryCache.invalidateQueries("Cards");
    } catch (err) {
      setError(err.response?.data?.message || "An error occurred");
      console.error("Remove Card error:", err.response);
    } finally {
      setLoading(false);
    }
  };

  return { removeCard, loading, error };
};
