import React from "react";
import illustrationOne from "../assets/img/svg/illustrations/illustration-1.svg";
import illustrationTwo from "../assets/img/svg/illustrations/illustration-2.svg";
import illustrationFive from "../assets/img/svg/illustrations/illustration-5.svg";
import illustrationSix from "../assets/img/svg/illustrations/illustration-6.svg";
import illustrationSeven from "../assets/img/svg/illustrations/illustration-7.svg";
import "../styles/CustomStyle.css";

function HowItWorks() {
  return (
    <div>
      <section className="slice py-5">
        <div className="container">
          <div className="row row-grid align-items-center">
            <div className="col-12 col-md-5 col-lg-6 order-md-2">
              <figure className="w-100">
                <img
                  src={illustrationOne}
                  alt="How it works illustration"
                  className="img-fluid mw-md-120"
                />
              </figure>
            </div>
            <div className="col-12 col-md-7 col-lg-6 order-md-1 pr-md-5">
              <h1 className="display-4 text-center text-md-left mb-3">
                Elevate Your{" "}
                <strong className="text-primary">Content Creation</strong> Game
              </h1>
              <p className="lead text-center text-md-left text-muted">
                AITube makes publishing your AI-generated videos seamless and
                effortless. Whether it’s text-to-video or voice-to-video, you’re
                just a few steps away from sharing your creativity with the
                world and growing your audience.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="slice slice-lg pt-lg-6 pb-0 pb-lg-6">
        <div className="container">
          <div className="row justify-content-lg-end">
            <div className="col-lg-5 text-lg-right">
              <p className="lh-180 mb-3">
                Empowering creators to share their AI-driven innovations with
                the world.
              </p>
            </div>
            <div className="col-lg-6">
              <h3 className="display-4 lh-130">
                <strong className="text-primary">3 Simple Steps</strong> to
                Publish Your AI-Generated Videos
              </h3>
            </div>
          </div>
          <div className="row mt-5">
            <div className="col-md-4">
              <div className="card border-0 bg-soft-danger">
                <div className="card-body pb-5">
                  <div className="pt-4 pb-5">
                    <img
                      src={illustrationFive}
                      alt="Sign-in illustration"
                      className="img-fluid img-center"
                      style={{ height: 200 }}
                    />
                  </div>
                  <h5 className="h4 lh-130 text-dark mb-3">
                    Sign In with Ease
                  </h5>
                  <p className="text-dark opacity-6 mb-0">
                    Use your Google account for a quick and seamless login
                    experience.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="card border-0 bg-soft-success mt-lg-5">
                <div className="card-body pb-5">
                  <div className="pt-4 pb-5">
                    <img
                      src={illustrationSix}
                      alt="Profile update illustration"
                      className="img-fluid img-center"
                      style={{ height: 200 }}
                    />
                  </div>
                  <h5 className="h4 lh-130 text-dark mb-3">
                    Customize Your Channel
                  </h5>
                  <p className="text-dark opacity-6 mb-0">
                    Update your profile picture and channel name to reflect your
                    brand.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="card border-0 bg-soft-warning mt-lg-7">
                <div className="card-body pb-5">
                  <div className="pt-4 pb-5">
                    <img
                      src={illustrationSeven}
                      alt="Upload video illustration"
                      className="img-fluid img-center"
                      style={{ height: 200 }}
                    />
                  </div>
                  <h5 className="h4 lh-130 text-dark mb-3">
                    Upload Your Masterpiece
                  </h5>
                  <p className="text-dark opacity-6 mb-0">
                    Upload your AI-generated video, add a title, description, or
                    the prompt used to create it, and share it with the world.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="slice slice-lg">
        <div className="container">
          <div className="row row-grid justify-content-around align-items-center">
            <div className="col-lg-6 order-lg-2 ml-lg-auto pl-lg-6">
              <h5 className="h2 mt-4">For Consumers</h5>
              <p className="lead lh-190 my-4">
                AITube is the ultimate destination for discovering AI Content
                Creators and exploring their innovative works from around the
                globe.
              </p>
              <ul className="list-unstyled">
                <li className="py-2">
                  <div className="d-flex align-items-center">
                    <div>
                      <div className="icon icon-shape icon-primary icon-sm rounded-circle mr-3">
                        <i className="fas fa-store-alt"></i>
                      </div>
                    </div>
                    <div>
                      <span className="h6 mb-0">
                        Learn more about AI Content Creators and their
                        creations.
                      </span>
                    </div>
                  </div>
                </li>
                <li className="py-2">
                  <div className="d-flex align-items-center">
                    <div>
                      <div className="icon icon-shape icon-warning icon-sm rounded-circle mr-3">
                        <i className="fas fa-palette"></i>
                      </div>
                    </div>
                    <div>
                      <span className="h6 mb-0">
                        Explore AI-generated videos for education and
                        entertainment.
                      </span>
                    </div>
                  </div>
                </li>
                <li className="py-2">
                  <div className="d-flex align-items-center">
                    <div>
                      <div className="icon icon-shape icon-success icon-sm rounded-circle mr-3">
                        <i className="fas fa-cog"></i>
                      </div>
                    </div>
                    <div>
                      <span className="h6 mb-0">
                        Search and discover AI-generated video content
                        effortlessly.
                      </span>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
            <div className="col-lg-6 order-lg-1">
              <div className="position-relative">
                <img
                  src={illustrationTwo}
                  alt="For consumers illustration"
                  className="img-fluid"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default HowItWorks;
