import styled from "styled-components";

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  background: ${(props) => props.theme.lightNavyBlue};
  z-index: 99;
  padding: 0.7rem 1.5rem;

  input {
    width: 460px;
  }

  .hamburger-icon {
    display: none;
  }

  .logo span {
    position: relative;
    top: 1px;
  }

  ul {
    list-style: none;
    display: flex;
    position: relative;
  }

  li svg {
    // margin-right: 1.7rem;
    position: relative;
  }

  ul {
    display: flex;
    align-items: center;
  }

  img {
    position: relative;
    top: 3px;
  }

  .auth-hidden {
    display: none;
  }

  @media (min-width: 601px) {
    .auth-hidden {
      display: block;
      max-width: 180px;
    }
  }

  @media screen and (max-width: 1093px) {
    .hamburger-icon {
      display: block;
    }
  }

  @media screen and (max-width: 1050px) {
    input {
      width: 150px;
    }
  }

  @media screen and (max-width: 768) {
    input {
      display: hidden;
    }
  }

  @media screen and (max-width: 500px) {
    .hamburger-icon {
      display: none;
    }

    li svg {
      width: 30px;
      height: 30px;
      margin-right: 1rem;
      position: relative;
    }
  }

  // @media screen and (max-width: 260px) {
  //   li svg {
  //     display: none;
  //   }
  // }
`;

export default Wrapper;
