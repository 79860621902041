import React from "react";
import imgCover from "../assets/img/img-bg.jpg";
import imgOne from "../assets/img/img-1.jpg";
import imgTwo from "../assets/img/img-2.jpg";
import imgThree from "../assets/img/img-3.jpg";
import imgFour from "../assets/img/img-4.jpg";
import "../styles/CustomStyle.css";

function AboutUs() {
  return (
    <div>
      <section
        className="slice py-8 bg-dark bg-cover bg-size--cover"
        style={{ backgroundImage: "url(" + imgCover + ")" }}
      >
        <span className="mask bg-gradient-dark opacity-9"></span>
        <div className="container d-flex align-items-center text-center text-lg-left pt-5 pb-5 pt-lg-6">
          <div className="col px-0">
            <div className="row row-grid align-items-center">
              <div className="col-lg-8 text-center text-lg-left">
                <h1 className="text-white mb-4">
                  AITube: The Premier Platform for AI-Generated Videos by
                  Creators.
                </h1>
                <p className="lead text-white opacity-8">
                  At AITube, we are redefining the way video content is created,
                  shared, and experienced. Powered by cutting-edge artificial
                  intelligence, our platform empowers creators to transform
                  ideas into stunning video masterpieces with unparalleled ease
                  and efficiency.
                </p>
                <p className="lead text-white opacity-8">
                  We are entering the era of AI-powered creativity, where
                  text-to-video and voice-to-video technologies are
                  revolutionizing how content creators bring their ideas to
                  life. At AITube, we recognize the need for a dedicated
                  platform where this new wave of 'AI Content Creators' can
                  showcase their innovation. Our mission is to empower creators
                  by providing a space to share their unique, AI-driven works
                  with the world, connect with audiences, and grow their online
                  presence.
                </p>
                <p className="lead text-white opacity-8">
                  Whether you're an independent creator, a business, or an
                  enthusiast exploring the possibilities of AI, AITube provides
                  the tools you need to bring your vision to life. Our platform
                  connects creators with audiences worldwide, offering a vibrant
                  space for sharing and discovering AI-driven video content.
                </p>
                <p className="lead text-white opacity-8">
                  At AITube, we're not just a video platform—we're a movement
                  toward the future of content creation. Join us and experience
                  how AI is transforming creativity, one frame at a time.
                </p>
                <h2 className="text-white mt-5">Our Services</h2>
                <ul className="text-white opacity-8">
                  <li>
                    <strong>AI Video Creation:</strong> Effortlessly generate
                    videos from text or voice inputs.
                  </li>
                  <li>
                    <strong>Content Sharing Platform:</strong> Upload, share,
                    and monetize your AI-powered creations.
                  </li>
                  <li>
                    <strong>AI Enhancement Tools:</strong> Enhance your videos
                    with lifelike visuals, voiceovers, and animations using
                    advanced AI technology.
                  </li>
                  <li>
                    <strong>Community Engagement:</strong> Connect with creators
                    and audiences on an interactive, global platform.
                  </li>
                </ul>
                <p className="lead text-white opacity-8">
                  Welcome to the future of video. Welcome to AITube.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="slice slice-lg">
        <div className="container">
          <span className="badge badge-primary badge-pill">
            Our Vision & Mission
          </span>
          <div className="row mt-4">
            <div className="col-lg-6 pr-lg-5">
              <p className="h5 lh-180 mb-3">
                AITube's vision is to lead the world into a new era of
                AI-powered creativity, where groundbreaking technologies like
                text-to-video and voice-to-video empower creators to share their
                stories and redefine how video content is experienced globally.
              </p>
            </div>
            <div className="col-lg-6 pr-lg-5">
              <p className="h5 lh-180 mb-3">
                Our mission is to provide creators with innovative AI-driven
                tools and a dedicated platform to transform ideas into stunning
                video masterpieces, connect with audiences worldwide, and
                revolutionize the way video content is created, shared, and
                consumed.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="slice slice-lg">
        <div className="container">
          <div className="row">
            <div className="col-lg-5 col-6">
              <img
                src={imgOne}
                alt="Company team at the event"
                className="rounded-lg img-fluid"
              />
              <div className="text-right mt-4">
                <img
                  src={imgTwo}
                  alt="Company team at the event"
                  className="rounded-lg img-fluid w-50"
                />
              </div>
            </div>
            <div className="col-lg-7 col-6">
              <img
                src={imgThree}
                alt="Company team at the event"
                className="rounded-lg img-fluid w-75"
              />
              <div className="text-right mt-4">
                <img
                  src={imgFour}
                  alt="Company team at the event"
                  className="rounded-lg img-fluid"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default AboutUs;
