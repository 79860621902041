import { useState } from "react";
import { queryCache } from "react-query";
import { client } from "utils/api-client";
export const useAddCard = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const addCard = async (paymentMethodId) => {
    try {
      setLoading(true);
      setError(null);

      const res = await client.post("/payments/addCard", { paymentMethodId });
      await queryCache.invalidateQueries("Cards");
    } catch (err) {
      setError(err.response?.data?.message || "An error occurred");
      console.error("Add Card error:", err.response);
    } finally {
      setLoading(false);
    }
  };

  return { addCard, loading, error };
};
