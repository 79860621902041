import ShortVideo from "components/ShortVideo";
import React, { useEffect, useRef, useState } from "react";
import { FaArrowDown, FaArrowUp } from "react-icons/fa6";
import { useQuery } from "react-query";
import { useParams, useHistory } from "react-router-dom";
import ShortsWrapper from "styles/Shorts";
import { client } from "utils/api-client";
import { RxCross1 } from "react-icons/rx";
import ShortsCommentBox from "components/ShortsCommentBox";
import ShareModal from "components/ShareModal";

const LIMIT = 5;
const random = Math.floor(Math.random() * 10000);
const ShortsPage = () => {
  const history = useHistory();
  const { id } = useParams();

  const [page, setPage] = useState(1);
  const { data, isLoading: isLoadingVideo } = useQuery(
    ["getShorts", page],
    () =>
      client
        .get(
          `/videos/shorts?page=${page}&pageSize=${LIMIT}&random=${random}&videoId=${id}`
        )
        .then((res) => res.data)
  );

  const containerRef = useRef(null);
  const cardRefs = useRef([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isMuted, setIsMuted] = useState(true);
  const [volume, setVolume] = useState(0);
  const [videos, setVideos] = useState([]);
  const [videoId, setVideoId] = useState(null);
  const [isShareModalOpen, setIsShareModalOpen] = useState(false);
  const [currentVideoId, setCurrentVideoId] = useState(null);

  useEffect(() => {
    setVideoId(null);
  }, [id]);

  useEffect(() => {
    if (data) {
      setVideos([...videos, ...data.videos]);
    }
  }, [data]);

  const [isFullscreen, setIsFullscreen] = useState(false);

  const toggleFullscreen = () => {
    const fullscreenDiv = document.getElementById("fullscreenDiv");
    if (!document.fullscreenElement) {
      fullscreenDiv.requestFullscreen().catch((err) => {
        console.error(`Error attempting to enable fullscreen: ${err.message}`);
      });
    } else {
      document.exitFullscreen();
    }
    setIsFullscreen(!isFullscreen);
  };

  const scrollToCard = (index) => {
    if (index >= 0 && index < cardRefs.current.length) {
      cardRefs.current[index].scrollIntoView({ behavior: "smooth" });
      setCurrentIndex(index);
    }
  };
  const handleScrollUp = () => {
    if (currentIndex > 0) {
      scrollToCard(currentIndex - 1);
    }
  };

  const handleScrollDown = (index) => {
    if (index < cardRefs.current.length - 1) {
      scrollToCard(index + 1);
    }
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const index = parseInt(entry.target.dataset.index);
            setCurrentIndex(index);
            if (index === videos.length - 2 && page < data.totalPages)
              setPage(page + 1);
            history.push(`/shorts/${entry.target.dataset.id}`);
          }
        });
      },
      {
        threshold: 0.75, // Adjust the threshold to trigger visibility
      }
    );

    const videoElements = containerRef.current.querySelectorAll("[data-id]");
    videoElements.forEach((video) => observer.observe(video));

    return () => {
      videoElements.forEach((video) => observer.unobserve(video));
    };
  }, [videos]);

  const updateCommentsCount = (status) => {
    if (videoId) {
      const video = videos.find((v) => v.id === videoId);
      if (video) {
        if (status === "add") {
          video._count.comments += 1;
        } else {
          video._count.comments -= 1;
        }
        setVideos([...videos]);
      }
    }
  };

  const shareVideo = (id) => {
    setCurrentVideoId(id);
    setIsShareModalOpen(true);
  };

  return (
    <ShortsWrapper id="fullscreenDiv">
      {isShareModalOpen && (
        <ShareModal
          videoId={currentVideoId}
          onHide={() => setIsShareModalOpen(false)}
        />
      )}
      <div className="short-videos-container d-flex justify-content-between">
        <div ref={containerRef} className="short-container">
          {videos?.map((video, index) => (
            <div
              className="h-100 w-100"
              key={index}
              ref={(el) => (cardRefs.current[index] = el)}
              data-id={video.id}
              data-index={index}
            >
              <ShortVideo
                isMuted={isMuted}
                setIsMuted={setIsMuted}
                isFullscreen={isFullscreen}
                toggleFullscreen={toggleFullscreen}
                video={video}
                volume={volume}
                setVolume={setVolume}
                setVideos={setVideos}
                setVideoId={setVideoId}
                openShareModal={shareVideo}
                handleScrollDown={() => handleScrollDown(index)}
              />
            </div>
          ))}
        </div>
        {videoId && (
          <ShortsCommentBox
            updateCommentsCount={updateCommentsCount}
            id={videoId}
            onHide={() => setVideoId(null)}
          />
        )}
      </div>
      <div className="navigators">
        <div onClick={handleScrollUp} className="right-control-icon">
          <FaArrowUp size={23} />
        </div>
        <div
          onClick={() => handleScrollDown(currentIndex)}
          className="right-control-icon"
        >
          <FaArrowDown size={23} />
        </div>
      </div>
    </ShortsWrapper>
  );
};

export default ShortsPage;
