// @ts-nocheck
import styled, { css } from "styled-components";

const Button = styled.button`
  padding: 10px 16px;
  font-family: ${(props) => props.font};
  background: ${(props) => props.theme.cyan};
  color: ${(props) => props.theme.navyBlue};
  border: 1px solid ${(props) => props.theme.cyan};
  border-radius: 1.5rem;
  font-weight: 400;
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.25;
  letter-spacing: 0.02857em;
  margin: 0 0.3rem;

  @media screen and (max-width: 400px) {
    font-size: 14px;
    padding: 5px 8px;
  }

  ${(props) =>
    props.grey &&
    css`
      background: ${(props) => props.theme.thirdColor};
      border: 1px solid ${(props) => props.theme.thirdColor};
      color: ${(props) => props.theme.fourthColor};
    `}
  ${(props) =>
    props.className?.includes("w-full") &&
    css`
      width: 100%;
    `}
  ${(props) =>
    props.disabled &&
    css`
      background: #627b7d;
      border-color: #627b7d;
      cursor: not-allowed;
    `}
`;

export default Button;
