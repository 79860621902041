import React, { useEffect, useRef, useState } from "react";
import {
  BiSolidCommentDetail,
  BiSolidDislike,
  BiSolidLike,
  BiSolidVolumeMute,
} from "react-icons/bi";
import { IoIosShareAlt, IoMdVolumeHigh, IoMdVolumeMute } from "react-icons/io";
import { MdFullscreen, MdFullscreenExit } from "react-icons/md";
import Button from "styles/Button";
import ProgressBar from "./ShortsProgressBar";
import ShortVideoPlayer from "./ShortVideoPlayer";
import { FaPause, FaPlay } from "react-icons/fa6";
import { useParams } from "react-router-dom";
import useAuthAction from "hooks/use-auth-action";
import { dislikeVideo, likeVideo, toggleSubscribeUser } from "utils/api-client";
const ShortVideo = ({
  toggleFullscreen,
  isFullscreen,
  video,
  isMuted,
  setIsMuted,
  volume,
  setVolume,
  setVideos,
  setVideoId,
  openShareModal,
  handleScrollDown,
}) => {
  const handleAuthAction = useAuthAction();
  const { id } = useParams();
  const playerRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [showVolume, setShowVolume] = useState(false);
  const [triggerAnimation, setTriggerAnimation] = useState(false);
  const [isLiked, setIsLiked] = useState(video.hasLiked);
  const [isDisliked, setIsDisliked] = useState(video.hasDisliked);
  const [likesCount, setLikesCount] = useState(video?._count?.videoLikes);
  const [commentsCount, setCommentsCount] = useState(video?._count?.comments);
  const [hasSubscribed, setHasSubscribed] = useState(video.hasSubscribed);
  const [isFullDesc, setIsFullDesc] = useState(false);

  useEffect(() => {
    setHasSubscribed(video.hasSubscribed);
    setCommentsCount(video?._count?.comments);
  }, [video.hasSubscribed, video?._count?.comments]);

  const handleFullscreenChange = () => {
    toggleFullscreen();
  };

  useEffect(() => {
    if (!playerRef.current) return;
    setIsMuted(playerRef.current.muted());
    // Update play state on player events
    const handlePlay = () => setIsPlaying(true);
    const handlePause = () => setIsPlaying(false);
    const handleVolumeChange = () => {
      setVolume(playerRef.current.volume());
      setIsMuted(playerRef.current.muted());
    };

    playerRef.current.on("play", handlePlay);
    playerRef.current.on("pause", handlePause);
    playerRef.current.on("volumechange", handleVolumeChange);

    return () => {
      playerRef.current.off("play", handlePlay);
      playerRef.current.off("pause", handlePause);
      playerRef.current.off("volumechange", handleVolumeChange);
    };
  }, [playerRef.current]);

  const onPlayerReady = (player) => {
    playerRef.current = player;
  };

  const handlePlayPause = () => {
    if (!playerRef.current) return;

    const player = playerRef.current;

    // Check if the video is ready to play
    const isReadyToPlay = player.readyState() >= 3; // 3 means "HAVE_FUTURE_DATA" (ready for playback)

    if (!isReadyToPlay) {
      console.log("Video is not ready to play yet.");
      return;
    }

    if (player.paused()) {
      player.play().catch((error) => {
        console.error("Error while trying to play the video:", error);
      });
    } else {
      player.pause();
    }
  };

  const handleVolumeChange = (e) => {
    if (!playerRef.current) return;
    const newVolume = parseFloat(e.target.value);
    playerRef.current.volume(newVolume);
    playerRef.current.muted(false);
    setVolume(newVolume);
    // setIsMuted(false);
  };

  const toggleMute = () => {
    if (!playerRef.current) return;
    const newMuted = !playerRef.current.muted();
    playerRef.current.muted(newMuted);
    setIsMuted(newMuted);
  };

  useEffect(() => {
    if (!playerRef.current) return;

    const player = playerRef.current;

    if (video?.id === id) {
      // Play the current video if IDs match
      player.play().catch((error) => {
        console.error("Error while trying to play the video:", error);
      });
    } else {
      // Handle the previous video instance
      const isReadyToPause = player.readyState() >= 2; // 2: "HAVE_CURRENT_DATA"

      if (isReadyToPause) {
        player.pause();
        player.currentTime(0); // Reset the time if the video is ready
      } else {
        console.log("Previous video is not fully loaded yet, deferring pause.");
      }
    }
  }, [video?.id, id]);

  useEffect(() => {
    if (playerRef.current) {
      playerRef.current.muted(isMuted);
      if (!isMuted) {
        playerRef.current.volume(volume);
      }
    }
  }, [id]);

  const handleLike = async () => {
    try {
      setIsLiked(!isLiked);
      setIsDisliked(false);
      setLikesCount(isLiked ? likesCount - 1 : likesCount + 1);
      await handleAuthAction(likeVideo, video?.id);
    } catch (e) {
      setIsLiked(isLiked);
      setLikesCount(likesCount);
    }
  };

  const handleDislike = async () => {
    try {
      setIsDisliked(!isDisliked);
      setIsLiked(false);
      setLikesCount(isLiked ? likesCount - 1 : likesCount);
      await handleAuthAction(dislikeVideo, video?.id);
    } catch (e) {
      setIsLiked(isLiked);
      setIsDisliked(isDisliked);
      setLikesCount(likesCount);
    }
  };

  async function handleToggleSubscribe() {
    try {
      setHasSubscribed(!hasSubscribed);
      await handleAuthAction(toggleSubscribeUser, video?.user.id);
      setVideos((prev) =>
        prev.map((shortVideo) =>
          shortVideo?.user.id === video?.user.id
            ? { ...shortVideo, hasSubscribed: !video.hasSubscribed }
            : shortVideo
        )
      );
    } catch (err) {
      setHasSubscribed(hasSubscribed);
    }
  }

  return (
    <div className="short-video-container d-flex">
      <div className="short-video">
        <div
          className="w-100 h-100 d-flex align-items-center"
          onClick={handlePlayPause}
        >
          {video && (
            <ShortVideoPlayer
              video={video}
              previewUrl={video?.url}
              onPlayerReady={onPlayerReady}
              handleScrollDown={handleScrollDown}
            />
          )}
        </div>
        <div className="upper-controls">
          <div className="left-icons">
            <div className="control-icon" onClick={handlePlayPause}>
              {isPlaying ? <FaPause size={20} /> : <FaPlay size={20} />}
            </div>
            <div
              className="volume-control"
              onMouseEnter={() => {
                setShowVolume(true);
                setTriggerAnimation(true);
              }}
              onMouseLeave={() => {
                setTriggerAnimation(false);
                setTimeout(() => {
                  setShowVolume(false);
                }, 80);
              }}
              style={{ display: "flex" }}
            >
              <div onClick={toggleMute}>
                {isMuted ? (
                  <IoMdVolumeMute size={23} />
                ) : (
                  <IoMdVolumeHigh size={23} />
                )}
              </div>
              <input
                type="range"
                min="0"
                max="1"
                step="0.01"
                className={`volume-range ${
                  triggerAnimation
                    ? "volume-animation"
                    : "volume-hide-animation"
                } ${!showVolume ? "d-none" : ""}`}
                value={isMuted ? 0 : volume}
                onChange={handleVolumeChange}
                style={{
                  background: `linear-gradient(to right, white 0%, white ${
                    (isMuted ? 0 : volume) * 100
                  }%, rgba(255, 255, 255, 0.3) ${
                    (isMuted ? 0 : volume) * 100
                  }%)`,
                }}
              />
            </div>
          </div>
          <div onClick={handleFullscreenChange} className="control-icon">
            {isFullscreen ? (
              <MdFullscreenExit size={25} />
            ) : (
              <MdFullscreen size={25} />
            )}
          </div>
        </div>
        <div className="bottom-details w-100">
          <div className="d-flex channel-info align-items-center">
            <div className="channel-img">
              <img src={video?.user?.avatar} alt="" />
            </div>
            <h3 className="channel-name m-0">@{video?.user?.username}</h3>
            <Button onClick={handleToggleSubscribe}>
              {!hasSubscribed ? "Follow" : "Following"}
            </Button>
          </div>
          <p
            onClick={() => setIsFullDesc(!isFullDesc)}
            className="video-caption"
            style={{ textWrap: isFullDesc ? "wrap" : "nowrap" }}
          >
            {video?.description}
          </p>
          <ProgressBar player={playerRef.current} />
        </div>
      </div>
      <div className="right-controls d-flex flex-column justify-content-end ml-3">
        <div className="control-container">
          <div
            className={`right-control-icon ${isLiked ? "active" : ""}`}
            onClick={handleLike}
          >
            <BiSolidLike size={23} />
          </div>
          <span>{likesCount}</span>
        </div>
        <div className="control-container" onClick={handleDislike}>
          <div className={`right-control-icon ${isDisliked ? "active" : ""}`}>
            <BiSolidDislike size={23} />
          </div>
          <span>Dislike</span>
        </div>
        <div className="control-container">
          <div
            onClick={() => setVideoId((prev) => (prev ? null : video?.id))}
            className="right-control-icon"
          >
            <BiSolidCommentDetail size={23} />
          </div>
          <span>{commentsCount}</span>
        </div>
        <div className="control-container">
          <div
            onClick={() => openShareModal(video?.id)}
            className="right-control-icon"
          >
            <IoIosShareAlt size={23} />
          </div>
          <span>Share</span>
        </div>
      </div>
    </div>
  );
};

export default ShortVideo;
