import React, { useRef } from "react";
import Wrapper from "styles/ShareModal";
import { CloseIcon } from "./Icons";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa";
import mix from "../assets/mix.svg";
import whatsapp from "../assets/whatsapp.svg";
import facebook from "../assets/facebook.svg";
import x from "../assets/x.svg";
import email from "../assets/email.svg";
import kakaotalk from "../assets/kakaotalk.svg";
import reddit from "../assets/reddit.svg";
import vk from "../assets/vk.svg";
import ok from "../assets/ok.svg";
import pinterest from "../assets/pinterest.svg";
import tumbler from "../assets/tumbler.svg";
import linkedin from "../assets/linkedin.svg";
import Button from "styles/Button";
import { useSnackbar } from "react-simple-snackbar";

const ShareModal = ({ onHide, videoId }) => {
  const url = `https://aivideostube.com/shorts/${videoId}`;
  const scrollerRef = useRef(null);
  const [snackbar] = useSnackbar();

  const scrollItems = (direction) => {
    if (scrollerRef.current) {
      const scroller = scrollerRef.current;
      const itemWidth = scroller.querySelector(".social-item").offsetWidth;
      const gap = 20; // Match the gap in CSS
      const scrollAmount = itemWidth + gap;

      scroller.scrollLeft +=
        direction === "right" ? scrollAmount : -scrollAmount;
    }
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(url);
    snackbar("Copied to clipboard");
  };

  const redirectToUrl = (redirectUrl) => {
    window.open(redirectUrl, "_blank");
  };

  return (
    <Wrapper>
      <div className="share-modal">
        <div className="modal-header">
          <h3>Share</h3>
          <CloseIcon onClick={onHide} />
        </div>
        <div className="py-3 px-4">
          <div className="socials mt-2">
            <div className="socials-container" ref={scrollerRef}>
              <div
                onClick={() =>
                  redirectToUrl(
                    `https://wa.me/?text=${encodeURIComponent(url)}`
                  )
                }
                className="social-item"
              >
                <img src={whatsapp} alt="mix" />
              </div>
              <div
                onClick={() =>
                  redirectToUrl(
                    `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
                      url
                    )}`
                  )
                }
                className="social-item"
              >
                <img src={facebook} alt="mix" />
              </div>
              <div
                onClick={() =>
                  redirectToUrl(
                    `https://twitter.com/intent/tweet?url=${encodeURIComponent(
                      url
                    )}`
                  )
                }
                className="social-item"
              >
                <img src={x} alt="mix" />
              </div>
              <div
                onClick={() =>
                  redirectToUrl(`mailto:?body=${encodeURIComponent(url)}`)
                }
                className="social-item"
              >
                <img src={email} alt="mix" />
              </div>
              <div
                onClick={() =>
                  redirectToUrl(
                    `https://story.kakao.com/share?url=${encodeURIComponent(
                      url
                    )}`
                  )
                }
                className="social-item"
              >
                <img src={kakaotalk} alt="mix" />
              </div>
              <div
                onClick={() =>
                  redirectToUrl(
                    `https://reddit.com/submit?url=${encodeURIComponent(url)}`
                  )
                }
                className="social-item"
              >
                <img src={reddit} alt="mix" />
              </div>
              <div
                onClick={() =>
                  redirectToUrl(
                    `https://vk.com/share.php?url=${encodeURIComponent(url)}`
                  )
                }
                className="social-item"
              >
                <img src={vk} alt="mix" />
              </div>
              <div
                onClick={() =>
                  redirectToUrl(
                    `https://connect.ok.ru/dk?st.cmd=WidgetSharePreview&st.shareUrl=${encodeURIComponent(
                      url
                    )}`
                  )
                }
                className="social-item"
              >
                <img src={ok} alt="mix" />
              </div>
              <div
                onClick={() =>
                  redirectToUrl(
                    `https://pinterest.com/pin/create/button/?url=${encodeURIComponent(
                      url
                    )}`
                  )
                }
                className="social-item"
              >
                <img src={pinterest} alt="mix" />
              </div>
              <div
                onClick={() =>
                  redirectToUrl(
                    `https://tumblr.com/share/link?url=${encodeURIComponent(
                      url
                    )}`
                  )
                }
                className="social-item"
              >
                <img src={tumbler} alt="mix" />
              </div>
              <div
                onClick={() =>
                  redirectToUrl(
                    `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(
                      url
                    )}`
                  )
                }
                className="social-item"
              >
                <img src={linkedin} alt="mix" />
              </div>
              <div
                onClick={() =>
                  redirectToUrl(
                    `https://mix.com/add?url=${encodeURIComponent(url)}`
                  )
                }
                className="social-item"
              >
                <img src={mix} alt="mix" />
              </div>
              <div
                onClick={() => scrollItems("left")}
                className="right-control-icon social-navigator left"
              >
                <FaAngleLeft size={21} />
              </div>
              <div
                onClick={() => scrollItems("right")}
                className="right-control-icon social-navigator right"
              >
                <FaAngleRight size={21} />
              </div>
            </div>
          </div>
          <div className="video-url">
            <p>{url}</p>
            <Button onClick={handleCopy} className="m-0">
              Copy
            </Button>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

export default ShareModal;
